{
    "Taisei Corporation": "大成建設",
    "Taisei Content": " 2023 年に創業 150 を迎えた大成建設は、これまで、日本国内・海外で多くの大規模プロジェクトを開発、建設してきました。<p style='padding-top: 10px'>2020 年、大成建設はベトナム初の日系企業単独投資であるオフィスタワープロジェクト「Taisei Square Hanoi」を発表しました。このプロジェクトは開発・設計・施工の全てを大成建設グループが担います。</p>",
    "NOTABLE ACHIEVEMENTS IN VIETNAM": "ベトナムでの実績",
    "Developed and Constructed by Taisei": "開発・施工事例",
    "ROYGENT PARKS HANOI": "ROYGENT PARKS HANOI",
    "ROYGENT PARKS HANOI CONTENT": "日系パートナー企業と組んで開発した日本品質のホスピタリティを提供する 24 階建てのホテル兼サービスアパートメント。",
    "T2 NOI BAI INTL AIRPORT": "<p>ﾉｲﾊﾞｲ国際空港第2ﾀｰﾐﾅﾙ</p>",
    "T2 NOI BAI INTL AIRPORT CONTENT": "年間航空旅客数 15 百万人の新しい国際線専用のターミナル。大成建設は日本政府の ODA 事業にゼネコンとして参加。",
    "Constructed by Taisei": "施工事例",
    "CAN THO BRIDG CONTENT": "カントー市のシンボルであるハウ川にかかる地域最大の斜張橋。大成建設他日系2社によって建設。",
    "NOTABLE ACHIEVEMENTS IN JAPAN": "日本での実績",
    "THE OKURA TOKYO": "THE OKURA TOKYO",
    "THE OKURA TOKYO CONTENT": "ホテルオークラの建替え事業。再開発の計画・開発業務には大成建設が中心となって取り組み日本のおもてなしと豪華さを体現。",
    "OCHANOMIZU SOLACITY": "御茶ノ水ソラシティ",
    "OCHANOMIZU SOLACITY CONTENT": "大成建設が他4社とともに投資し、開発業務・設計・施工を担ったオフィス・商業複合ビル。学術文化の豊かな御茶ノ水駅前にバリアフリーかつサスティナブルな環境を提供。",
    "NATIONAL STADIUM": "日本国立競技場",
    "NATIONAL STADIUM CONTENT": "隈研吾建築都市設計事務所および梓設計と大成建設の共同設計で、大成建設が建設。構造の一部に木材を使用。軒庇に使用している木材は47都道府県から調達。",
    "TAISEI DEVELOPMENT HANOI": "TAISEI DEVELOPMENT HANOI",
    "TAISEI DEVELOPMENT HANOI CONTENT": "Taisei Square Hanoi の投資・運営を実施する大成建設の 100％ 子会社です。日本人とベトナム人からなる少数精鋭のチームがお客様に日本基準の品質とサービスをお届けするために最善を尽くしています。",
    "OUR PARTNERS": "パートナー企業",
    "Main Contractor & Architect": "設計・施工者",
    "Design Consultant": "設計コンサルタント",
    "M&E Contractor KINDEN": "電気設備",
    "M&E Contractor YURTEC": "機械設備",
    "Project Management & Site Supervision": "監理者",
    "Strategic Leasing & Marketing Consultant": "リーシング <br /> コンサルタント",
    "Property Management Support": "ビル管理サポート",
    "Security Service": "警備",
    "Cleaning Service": "清掃",
    "Developed and": "開発および",
    "CAN THO BRIDGE": "カントー橋",
    "CONTACT US": "お問い合わせ",
    "EXPLORE": "EXPLORE"
}
