export const amenities = [
    {
        key: "car",
        icon: {
            title: "Grand Entrance",
            normal: "./img/car.png",
            activated: "./img/car-h.png",
            className: ""
        },
        image: "./img/ame-1.png",
        title: "Grand Entrance Title",
        description: "Grand Entrance Description"
    },
    {
        key: "reception",
        icon: {
            title: "Welcome Lobby",
            normal: "./img/information-desk.png",
            activated: "./img/information-desk-h.png",
            className: ""
        },
        image: "./img/ame-2.png",
        title: "Welcome Lobby Title",
        description: "Welcome Lobby Description"
    },
    {
        key: "retail",
        icon: {
            title: "Retail Podium",
            normal: "./img/coffee.png",
            activated: "./img/coffee-h.png",
            className: ""
        },
        image: "./img/ame-3.png",
        title: "Retail Podium Title",
        description: "Retail Podium Description"
    },
    {
        key: "restroom",
        icon: {
            title: "Japanese Standard Restroom",
            normal: "./img/toilet.png",
            activated: "./img/toilet-h.png",
            className: ""
        },
        image: "./img/ame-4.png",
        title: "Japanese Standard Restroom Title",
        description: "Japanese Standard Restroom Description"
    },
    {
        key: "nursing",
        icon: {
            title: "Nursing Room",
            normal: "./img/mother.png",
            activated: "./img/mother-h.png",
            className: ""
        },
        image: "./img/ame-5.png",
        title: "Nursing Room Title",
        description: "Nursing Room Description"
    },
    {
        key: "pantry",
        icon: {
            title: "Wet Pantry",
            normal: "./img/tap.png",
            activated: "./img/tap-h.png",
            className: ""
        },
        image: "./img/ame-6.png",
        title: "Wet Pantry Title",
        description: "Wet Pantry Description"
    },
    {
        key: "parking",
        icon: {
            title: "Parking",
            normal: "./img/parking.png",
            activated: "./img/parking-h.png",
            className: ""
        },
        image: "./img/ame-7.png",
        title: "Parking Title",
        description: "Parking Description"
    }
];
