{
    "Overview": "Overview",
    "SubOverview": "Overview",
    "Concept": "Concept",
    "Location": "Location",
    "The Building": "The Building",
    "Key Features": "Key Features",
    "Amenities": "Amenities",
    "Sustainability": "Sustainability",
    "Leasing": "Leasing",
    "Office": "Office",
    "Retail": "Retail",
    "Virtual Tour": "Virtual Tour",
    "360Tour": "360° Tour",
    "Photo Gallery": "Photo Gallery",
    "Developer": "Developer",
    "SubDeveloper": "Developer",
    "Investor": "Investor",
    "Partners": "Partners",
    "Media": "Media",
    "News": "News",
    "Leasing Events": "Leasing Events",
    "Press": "Press",
    "Contact": "Contact",
    "Disclaimer": "Disclaimer",
    "ContentFooter": "Whilst every care has been taken to ensure accuracy in the preparation of the information and specification contained herein, no warranties whatsoever are given nor legal representations provided in respect thereon. Interested parties should rely on formal transaction documents. Visual representations including drawings, illustrations, photographs and art renderings portray artistic impressions only and are not to be taken as representations of fact or law. The developer reserves the right to modify features, units, plans or the development or any part thereof."
}
