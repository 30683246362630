import React, { useEffect } from "react";
import ContactUsButtonComponent from "../../../components/ContactUsButton";
import { Link } from "react-router-dom";
import { OverviewMobile, OverviewDisplayAlignItems, OverviewInfoMobile, OverviewTitleMobile, OverviewDescriptionMobile, OverviewButtonContact, OverviewBuildingImage, OverviewImageCustom, OverviewParagraphs, OverviewGrid, OverviewDivContent, OverviewIcon } from "../../../style/OverviewScreen";
import parse from "html-react-parser";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

const LocationMobile = () => {
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);

    const LinkClickedHandler = () => {
        ReactGA.event({
            category: "Click",
            action: "Click",
            label: "Location map taisei"
        });

        window.open("https://www.google.com/maps/place/Taisei+Square+Hanoi/@21.002469,105.793777,17z/data=!4m6!3m5!1s0x3135ad460ef012e1:0x73874de77f3e7407!8m2!3d21.0024691!4d105.7937768!16s%2Fg%2F11v60qvzf6?hl=en&entry=ttu", "_blank", "noopener");
    };
    return (
        <OverviewMobile id="location">
            <OverviewDisplayAlignItems>
                <OverviewInfoMobile>
                    <OverviewTitleMobile languagecode={currentLanguageCode} margin={"33px 0px 0px 0px"}>
                        {t("LOCATION")}
                    </OverviewTitleMobile>
                    <OverviewDescriptionMobile languagecode={currentLanguageCode} align={"justify"}>
                        {t("LOCATION DESCRIPTION")}
                    </OverviewDescriptionMobile>
                </OverviewInfoMobile>
                <OverviewBuildingImage>
                    <OverviewImageCustom className="location-map" alt="location-map" src="./img/location-map.png" onClick={LinkClickedHandler} />
                </OverviewBuildingImage>
                <OverviewInfoMobile>
                    <OverviewButtonContact>
                        <Link to="/contact">
                            <ContactUsButtonComponent width={"100%"} text={t("CONTACT US")} />
                        </Link>
                    </OverviewButtonContact>
                </OverviewInfoMobile>
                <OverviewGrid gap={"20px"} padding={"20px 0px"}>
                    <OverviewDivContent>
                        <OverviewIcon className="locations-airport" src="./img/airport.png" />
                        <OverviewParagraphs talign={"center"} tablet={"25px"}>
                            {t("30 MINS")}
                        </OverviewParagraphs>
                        <OverviewParagraphs talign={"none"} languagecode={currentLanguageCode} fsize={"11px"} linehg={"15px"} fweight={"600"}>
                            {parse(t("NOI BAI INTERNATIONAL AIRPORT"))}
                        </OverviewParagraphs>
                    </OverviewDivContent>
                    <OverviewDivContent>
                        <OverviewIcon className="locations-hoankiem" src="./img/hoankiem.png" />
                        <OverviewParagraphs talign={"center"} tablet={"25px"}>
                            {t("30 MINS")}
                        </OverviewParagraphs>
                        <OverviewParagraphs talign={"none"} languagecode={currentLanguageCode} fsize={"11px"} linehg={"15px"} fweight={"600"}>
                            {parse(t("HOAN KIEM DISTRICT"))}
                        </OverviewParagraphs>
                    </OverviewDivContent>
                </OverviewGrid>
                <OverviewGrid gap={"20px"} padding={"0px 0px 50px 0px"}>
                    <OverviewDivContent>
                        <OverviewIcon className="locations-badinh" src="./img/badinh.png" />
                        <OverviewParagraphs talign={"center"} tablet={"25px"}>
                            {t("15 MINS")}
                        </OverviewParagraphs>
                        <OverviewParagraphs talign={"none"} languagecode={currentLanguageCode} fsize={"11px"} linehg={"15px"} fweight={"600"}>
                            {parse(t("DONG DA AND BA DINH DISTRICT"))}
                        </OverviewParagraphs>
                    </OverviewDivContent>
                    <OverviewDivContent>
                        <OverviewIcon className="locations-highway" height={"70px"} width={"65px"} src="./img/highway.png" />
                        <OverviewParagraphs talign={"center"} tablet={"25px"}>
                            {t("3 MINS")}
                        </OverviewParagraphs>
                        <OverviewParagraphs talign={"none"} languagecode={currentLanguageCode} fsize={"11px"} linehg={"15px"} fweight={"600"}>
                            {parse(t("LANG HOA LAC HIGHWAY"))}
                        </OverviewParagraphs>
                    </OverviewDivContent>
                </OverviewGrid>
            </OverviewDisplayAlignItems>
        </OverviewMobile>
    );
};

export default LocationMobile;
