import React, { useRef, useState, useEffect, useCallback } from "react";
import { LeasingWrapper, LeasingImage, LeasingGroup, ElementLeasing, ContentLeasing, DivWrapperLeasing, LeasingDisplay, LeasingParagraphs, LeasingDivElement, LeasingContent, LeasingModalImage, DropdownItem, DropdownList, LeasingButtonWrapper, LeasingSpanWrapper, LinkItem, LeasingIcon, SliderShowLeasing, ModalContent } from "../../../style/LeasingScreen";
import PropTypes from "prop-types";
import { TextAnimation } from "../../../breakpoints";
import { animated } from "@react-spring/web";
import { useInView } from "react-intersection-observer";
import { floor16to20 } from "../Data/floor16to20.js";
import { floor13to15 } from "../Data/floor13to15.js";
import { floor8to11 } from "../Data/floor8to11.js";
import { floor3to7 } from "../Data/floor3to7.js";
import { officeDataDesktop } from "../Data/officeData.js";
import { Modal as LeasingModal } from "antd";
import ImageBlocker from "../../../components/ImageBlocker";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import ReactGA from "react-ga4";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const FloorsByOffice = ({ data }) => {
    const { height, name } = data;
    return (
        <ElementLeasing height={"37px"} width={"430px"} position={"relative"}>
            <DivWrapperLeasing width={"50px"} align={"right"} left={"30px"} color={"#333333"} size={"28px"} top={"8px"}>
                {name}
            </DivWrapperLeasing>
            <ContentLeasing height={height} width={"241px"} top={"0"} position={"relative"} left={"190px"}></ContentLeasing>
        </ElementLeasing>
    );
};

FloorsByOffice.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.number.isRequired,
        height: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
    }).isRequired
};

const FloorByOffice = ({ height, name, hvcolor }) => {
    return (
        <ElementLeasing height={"37px"} width={"430px"} position={"relative"}>
            <DivWrapperLeasing width={"50px"} align={"right"} left={"30px"} size={"28px"} top={"8px"} hvcolor={hvcolor}>
                {name}
            </DivWrapperLeasing>
            <ContentLeasing height={height} width={"241px"} top={"0"} position={"relative"} left={"190px"} hvcolor={hvcolor}></ContentLeasing>
        </ElementLeasing>
    );
};

FloorByOffice.propTypes = {
    height: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    hvcolor: PropTypes.string
};

const generateOfficeComponents = (floors) => {
    return floors.map((floor) => <FloorsByOffice key={floor.id.toString()} data={floor} />);
};

const OfficeSection = ({ onHeightChange }) => {
    const sectionRef = useRef(null);
    const [srcImage, setSRCImage] = useState("");
    const [isModalOpen, setModalOpen] = useState(false);
    const dropDTestFitRef = useRef(null);
    const btnTestFitRef = useRef(null);
    const btnSpecSheetRef = useRef(null);
    const dropDSpecSheetRef = useRef(null);
    const carouselRef = useRef(null);
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    const openModal = (src) => {
        ReactGA.event({
            category: "Click",
            action: "Click",
            label: `Leasing - see image ${src} in building.`
        });
        setModalOpen(true);
        setSRCImage(src);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const imageListInterior = ["interior-ideas-1.png", "interior-ideas-2.png", "interior-ideas-3.png"];

    const leasingElements = officeDataDesktop.map((office, index) => {
        let elementLeasingClassName = office.className;
        let element;

        if (elementLeasingClassName === "floorplan-16_20") {
            element = generateOfficeComponents(floor16to20);
        } else if (elementLeasingClassName === "floorplan-13_15") {
            element = generateOfficeComponents(floor13to15);
        } else if (elementLeasingClassName === "floorplan-12") {
            element = <FloorByOffice height={office.height} key={index} name={office.name} hvcolor={office.hvcolor} />;
        } else if (elementLeasingClassName === "floorplan-8_11") {
            element = generateOfficeComponents(floor8to11);
        } else if (elementLeasingClassName === "floorplan-3-7") {
            element = generateOfficeComponents(floor3to7);
        } else if (elementLeasingClassName === "floorplan-2" || elementLeasingClassName === "floorplan-1") {
            element = <FloorByOffice height={office.height} key={index} name={office.name} hvcolor={office.hvcolor} />;
        }

        return (
            <ElementLeasing key={index} className={office.className} height={office.height} width={office.width} top={office.top} position={office.position} left={office.left} onClick={() => openModal(office.pathModal)}>
                {element}
            </ElementLeasing>
        );
    });
    useEffect(() => {
        function handleClickOutside(event) {
            if (dropDTestFitRef.current && !dropDTestFitRef.current.contains(event.target) && btnTestFitRef.current && !btnTestFitRef.current.contains(event.target) && dropDSpecSheetRef.current && !dropDSpecSheetRef.current.contains(event.target) && btnSpecSheetRef.current && !btnSpecSheetRef.current.contains(event.target)) {
                setIsOpenDropDownTestFit(false);
                setIsOpenDropDownSpecsSheet(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    useEffect(() => {
        const sectionHeight = sectionRef.current ? sectionRef.current.clientHeight : 0;
        onHeightChange((prevHeight) => prevHeight + sectionHeight);
    }, [onHeightChange]);

    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);

    const [officeRef, inOfficeRef] = useInView();
    const descriptionSpring = TextAnimation(inOfficeRef);
    const [isOpenDropDownTestFit, setIsOpenDropDownTestFit] = useState(false);
    const [isOpenDropDownSpecsSheet, setIsOpenDropDownSpecsSheet] = useState(false);
    const [isOpenModalSpecSheet, setIsOpenModalSpecSheet] = useState(false);
    const [selectedSpecSheet, setSelectedSpecSheet] = useState(0);
    const [isOpenModalDropDown, setIsOpenModalDropDown] = useState(false);
    const [selectedItem, setSelectedItem] = useState("");
    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);
    const itemsTestFit = [
        { key: 1, name: "Plan A" },
        { key: 2, name: "Plan A-B-C" },
        { key: 3, name: "Plan A-B-C-D-E" },
        { key: 4, name: "Interior Ideas" }
    ];
    const itemsSpecsSheet = [
        { key: 1, name: "English", pdfLink: "./pdf/Specs_sheet_EN.pdf" },
        { key: 2, name: "日本語", pdfLink: "./pdf/Specs_sheet_JP.pdf" },
        { key: 3, name: "Tiếng Việt", pdfLink: "./pdf/Specs_sheet_VI.pdf" }
    ];

    const handleItemClick = (item) => {
        ReactGA.event({
            category: "Click",
            action: "Click",
            label: `Leasing - See image test fit ${item}`
        });
        setSelectedItem(item);
        setIsOpenModalDropDown(true);
        setNumPages(numPages);
        setPageNumber(1);
    };

    const DropdownListOfficeSpecSheet = useCallback(({ refProp, width, openDD, items, currentLanguageCode }) => {
        const handleOpenSpecSheet = (item) => {
            setSelectedSpecSheet(item.key - 1);
            setIsOpenModalSpecSheet((prev) => !prev);
        };
        return (
            <DropdownList ref={refProp} width={width} opendd={openDD ? "true" : "false"}>
                {items.map((item, index) => (
                    <DropdownItem key={index}>
                        <LinkItem languagecode={currentLanguageCode} onClick={() => handleOpenSpecSheet(item)}>
                            {item.name ?? item}
                        </LinkItem>
                    </DropdownItem>
                ))}
            </DropdownList>
        );
    }, []);
    const DropdownListOffice = useCallback(({ refProp, width, openDD, items, onItemClick }) => {
        return (
            <DropdownList ref={refProp} width={width} opendd={openDD ? "true" : "false"}>
                {items.map((item, index) => (
                    <DropdownItem languagecode={currentLanguageCode} key={index} onClick={() => onItemClick(item.key)}>
                        {item.name ?? item}
                    </DropdownItem>
                ))}
            </DropdownList>
        );
    }, []);
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    return (
        <LeasingDisplay id="office" ref={sectionRef}>
            {/* Popup leasing office */}
            <LeasingModal open={isOpenModalSpecSheet} onCancel={() => setIsOpenModalSpecSheet(false)} width={"auto"} centered footer={null} zIndex={"999999999"} closeIcon={true}>
                <Document file={`${itemsSpecsSheet[selectedSpecSheet].pdfLink}`} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page renderMode="svg" pageNumber={pageNumber} renderTextLayer={false} />
                </Document>
            </LeasingModal>

            <LeasingModal open={isOpenModalDropDown} onCancel={() => setIsOpenModalDropDown(false)} footer={null} width={"70vw"} zIndex={"999999999"} centered className="fullscreen-modal" closeIcon={true}>
                {selectedItem === 4 ? (
                    <>
                        <LeasingIcon width={"76px"} height={"76px"} src={"./img/arrow-left.png"} position={"absolute"} zindex={"999999999"} top={"50%"} transform={"translateY(-50%)"} onClick={() => carouselRef.current.prev()} />
                        <LeasingIcon width={"76px"} height={"76px"} src={"./img/arrow-right.png"} position={"absolute"} zindex={"999999999"} top={"50%"} right={"0"} transform={"translateY(-50%)"} onClick={() => carouselRef.current.next()} />

                        <SliderShowLeasing dots={false} ref={carouselRef} waitForAnimate={false} autoplay={false}>
                            {imageListInterior.map((image, index) => (
                                <ModalContent key={index}>
                                    <LeasingModalImage src={`./img/${image}`} objectfit={"contain"} height={"80vh"} />
                                </ModalContent>
                            ))}
                        </SliderShowLeasing>
                    </>
                ) : (
                    <LeasingModalImage src={`./img/testfit-${selectedItem}.png`} height={"80vh"} objectfit={"contain"} padding={"8px 0 0 0"} />
                )}
            </LeasingModal>

            {/* leasing office */}
            <LeasingWrapper height="2210px" width="1219px" className="office-space" ref={officeRef}>
                <LeasingParagraphs languagecode={currentLanguageCode} font={"'Barlow', Helvetica"} fweight={"700"} fsize={"47px"} linehg={"47px"} padding={"82px 0px 0px 0px"}>
                    <animated.div style={descriptionSpring}>{t("OFFICE SPACE FEATURES")}</animated.div>
                </LeasingParagraphs>
                {/* list office */}
                <LeasingContent className="feature" height={"230px"} padding={"50px 0 0 0"} width={"1219px"} justify={"center"} gap={"13px"}>
                    <LeasingDivElement width={"203px"} height={"230px"} className="measure" align={"center"}>
                        <LeasingGroup>
                            <LeasingImage src="./img/measure.png" height="80px" width="80px" />
                        </LeasingGroup>
                        <LeasingParagraphs languagecode={currentLanguageCode} padding={"30px 0px 0px 0px"} linehg={"21px"} color="#2C53AF">
                            {parse(t("2.7m of Clearance Ceiling Height Allows Better Air Circulation"))}
                        </LeasingParagraphs>
                    </LeasingDivElement>

                    <LeasingDivElement width={"203px"} height={"230px"} className="expand" align={"center"}>
                        <LeasingGroup height={"83px"}>
                            <LeasingImage src="./img/expand.png" height="60px" width="60px" margintop={"7px"} />
                        </LeasingGroup>
                        <LeasingParagraphs languagecode={currentLanguageCode} padding={"30px 0px 0px 0px"} linehg={"21px"} color="#2C53AF">
                            {parse(t("Column Free Layout Maximizes Space use Efficiency"))}
                        </LeasingParagraphs>
                    </LeasingDivElement>

                    <LeasingDivElement width={"203px"} height={"230px"} className="air-filter" align={"center"}>
                        <LeasingGroup height={"83px"}>
                            <LeasingImage src="./img/air-filter.png" height="60px" width="60px" margintop={"7px"} />
                        </LeasingGroup>
                        <LeasingParagraphs languagecode={currentLanguageCode} padding={"30px 0px 0px 0px"} linehg={"21px"} color="#2C53AF">
                            {parse(t("Air Conditioning System with Air Quality Control"))}
                        </LeasingParagraphs>
                    </LeasingDivElement>

                    <LeasingDivElement width={"203px"} height={"230px"} className="creative" align={"center"}>
                        <LeasingGroup height={"83px"}>
                            <LeasingImage src="./img/creative.png" height="80px" width="80px" />
                        </LeasingGroup>
                        <LeasingParagraphs languagecode={currentLanguageCode} padding={"30px 0px 0px 0px"} linehg={"21px"} color="#2C53AF">
                            {parse(t("LED Panel Lighting on Finished Concealed Ceiling"))}
                        </LeasingParagraphs>
                    </LeasingDivElement>

                    <LeasingDivElement width={"203px"} height={"230px"} className="tile" align={"center"}>
                        <LeasingGroup height={"83px"}>
                            <LeasingImage src="./img/tile.png" height="90px" width="90px" />
                        </LeasingGroup>
                        <LeasingParagraphs languagecode={currentLanguageCode} padding={"30px 0px 0px 0px"} linehg={"21px"} color="#2C53AF">
                            {parse(t("100m Raised Floor System Eases Out Renovation/ Alteration"))}
                        </LeasingParagraphs>
                    </LeasingDivElement>

                    <LeasingDivElement width={"203px"} height={"230px"} className="backup" align={"center"}>
                        <LeasingGroup height={"83px"}>
                            <LeasingImage src="./img/backup.png" height="80px" width="80px" />
                        </LeasingGroup>
                        <LeasingParagraphs languagecode={currentLanguageCode} padding={"30px 0px 0px 0px"} linehg={"21px"} color="#2C53AF">
                            {parse(t("100% Power Backup for Seamless Operation"))}
                        </LeasingParagraphs>
                    </LeasingDivElement>
                </LeasingContent>
                {/* test fit office */}
                <LeasingContent className="list-button" height={"40px"} margin={"20px 0 0 0"} justify={"center"}>
                    <LeasingDivElement position="relative" width={currentLanguageCode === "jp" ? "280px" : "235px"} className="fit-button" ref={btnTestFitRef}>
                        <LeasingButtonWrapper openbutton={isOpenDropDownTestFit ? "true" : "false"} onClick={() => setIsOpenDropDownTestFit(!isOpenDropDownTestFit)}>
                            <LeasingParagraphs languagecode={currentLanguageCode} font={"'Barlow', Helvetica"} color={"#323b4a"} fsize={"18px"} linehg={"21px"} fweight={"600"} padding={"0 0 3px 0"} shadow={"0px 1px 2px #353535"}>
                                <LeasingSpanWrapper>{t("TEST FIT")}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</LeasingSpanWrapper>
                                <LeasingSpanWrapper fsize={"21px"} fweight={"800"}>
                                    {!isOpenDropDownTestFit ? "+" : "-"}
                                </LeasingSpanWrapper>
                            </LeasingParagraphs>
                        </LeasingButtonWrapper>
                        <DropdownListOffice refProp={dropDTestFitRef} width={currentLanguageCode === "jp" ? "280px" : "235px"} openDD={isOpenDropDownTestFit} items={itemsTestFit} onItemClick={handleItemClick} />
                    </LeasingDivElement>
                    <LeasingDivElement position="relative" width={currentLanguageCode === "jp" ? "280px" : "235px"} className="specs-button">
                        <LeasingButtonWrapper ref={btnSpecSheetRef} openbutton={isOpenDropDownSpecsSheet ? "true" : "false"} onClick={() => setIsOpenDropDownSpecsSheet(!isOpenDropDownSpecsSheet)}>
                            <LeasingParagraphs languagecode={currentLanguageCode} font={"'Barlow', Helvetica"} color={"#323b4a"} fsize={"18px"} linehg={"21px"} fweight={"600"} padding={"0 0 3px 0"} shadow={"0px 1px 2px #353535"}>
                                <LeasingSpanWrapper>{t("SPECS SHEET")}&nbsp;&nbsp;&nbsp;&nbsp;</LeasingSpanWrapper>
                                <LeasingSpanWrapper fsize={"24px"} fweight={"800"}>
                                    {!isOpenDropDownSpecsSheet ? "+" : "-"}
                                </LeasingSpanWrapper>
                            </LeasingParagraphs>
                        </LeasingButtonWrapper>
                        <DropdownListOfficeSpecSheet refProp={dropDSpecSheetRef} width={currentLanguageCode === "jp" ? "280px" : "235px"} openDD={isOpenDropDownSpecsSheet} items={itemsSpecsSheet} />
                    </LeasingDivElement>
                </LeasingContent>
                {/* building office */}
                <LeasingContent className="group-building-floor" height={"1120px"} width={"1219px"} justify={"center"} direction={""} position={"relative"}>
                    <LeasingDivElement height={"874px"} width={"495px"} margin={"166px 0 0 30px"} zindex={"1000"} position={"absolute"}>
                        {leasingElements}
                    </LeasingDivElement>
                    <LeasingDivElement height={"1022px"} width={"610px"} zindex={"999"} padding={"50px 0px"} position={"absolute"}>
                        <ImageBlocker>
                            <LeasingImage height={"1022px"} src="./img/stackplan.png" fit="contain" width={"610px"} />
                        </ImageBlocker>
                    </LeasingDivElement>
                </LeasingContent>
                {/* modal office */}
                <LeasingModal open={isModalOpen} onCancel={closeModal} footer={null} width={"80vw"} zIndex={"999999999"} centered className="fullscreen-modal" closeIcon={true}>
                    <LeasingModalImage height={srcImage === "./img/floorplan-1.png" ? "100vh" : "90vh"} objectfit={"contain"} src={srcImage} />
                </LeasingModal>

                <LeasingContent className="floorplan-typical" justify={"center"} height={"610px"} width={"1202px"} border={"1px solid #707070"}>
                    <ImageBlocker>
                        <LeasingImage src="./img/floorplan_typical.png" width={"1200px"} height={"608px"} fit={"contain"} />
                    </ImageBlocker>
                </LeasingContent>
            </LeasingWrapper>
        </LeasingDisplay>
    );
};

OfficeSection.propTypes = {
    onHeightChange: PropTypes.func
};

export default OfficeSection;
