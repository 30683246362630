export const keyFeatures = [
    {
        key: "ceilingheight",
        icon: {
            title: "Column Free",
            normal: "./img/ceilingheight.png",
            activated: "./img/ceilingheight-a.png",
            className: ""
        },
        image: "./img/fea-1.png",
        title: "Column Free Title",
        description: "Column Free Description"
    },
    {
        key: "panorama",
        icon: {
            title: "Panoramic View",
            normal: "./img/panorama.png",
            activated: "./img/panorama-5.png",
            className: ""
        },
        image: "./img/fea-2.png",
        title: "Panoramic View Title",
        description: "Panoramic View Description"
    },
    {
        key: "airfilter",
        icon: {
            title: "Air Quality",
            normal: "./img/airfilter.png",
            activated: "./img/airfilter-a.png",
            className: ""
        },
        image: "./img/fea-3.png",
        title: "Air Quality Title",
        description: "Air Quality Description"
    },
    {
        key: "floor",
        icon: {
            title: "Raised Floor",
            normal: "./img/floor-2.png",
            activated: "./img/floor.png",
            className: ""
        },
        image: "./img/fea-4.png",
        title: "Raised Floor Title",
        description: "Raised Floor Description"
    },
    {
        key: "evelator",
        icon: {
            title: "Elevator",
            normal: "./img/elevator-5.png",
            activated: "./img/elevator.png",
            className: ""
        },
        image: "./img/fea-5.png",
        title: "Elevator Title",
        description: "Elevator Description"
    },
    {
        key: "generator",
        icon: {
            title: "Power Backup",
            normal: "./img/generator-4.png",
            activated: "./img/generator.png",
            className: ""
        },
        image: "./img/fea-6.png",
        title: "Power Backup Title",
        description: "Power Backup Description"
    },
    {
        key: "water",
        icon: {
            title: "Water Quality",
            normal: "./img/water-3.png",
            activated: "./img/water.png",
            className: ""
        },
        image: "./img/fea-7.png",
        title: "Water Quality Title",
        description: "Water Quality Description"
    }
];
