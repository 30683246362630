import styled from "styled-components";
import { Carousel } from "antd";

export const FadeInOut = styled.div`
    animation: fadeIn 2.3s;
`;

export const TheBuildingCarousel = styled(Carousel)`
    width: ${(props) => props.width ?? "100vw"};
    padding: ${(props) => props.padding ?? "0px 30px"};
    .slick-slide {
        width: 100vw;
    }
    .slick-dots li.slick-active button {
        background: #154dac;
        width: 40px;
    }
    .slick-dots li button {
        background: #444444;
        width: 40px;
    }
    .slick-dots li {
        width: 40px;
    }
    .slick-dots li.slick-active {
        width: 40px;
    }
    .slick-dots {
        margin: 40px 20px 0px 20px !important;
        position: relative !important;
    }
    @media (min-width: 786px) and (max-width: 1279px) {
        width: ${(props) => props.tablet ?? "98vw"};
    }
`;

export const TheBuildingSectionWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: ${(props) => props.width};
    background-color: ${(props) => props.bgcolor ?? ""};
`;

export const TheBuildingPositionDisplay = styled.div`
    position: ${(props) => props.position ?? "relative"};
    height: ${(props) => props.height ?? "auto"};
    width: ${(props) => props.width ?? "100%"};
    margin: ${(props) => props.margin};
`;

export const TheBuildingContent = styled.div`
    height: ${(props) => props.height};
    width: ${(props) => props.width ?? "1366px"};
    position: relative;
    display: ${(props) => props.display ?? ""};
    align-items: ${(props) => props.align ?? ""};
`;

export const TheBuildingPositionContent = styled.div`
    height: ${(props) => props.height ?? ""};
    width: ${(props) => props.width ?? ""};
    position: ${(props) => props.position ?? "absolute"};
    bottom: ${(props) => props.bottom ?? "0"};
    left: ${(props) => props.left ?? "0"};
    right: ${(props) => props.right ?? "0"};
`;

export const TheBuildingDivWrapper = styled.div`
    color: ${(props) => props.color ?? "#353535"};
    font-family: ${(props) => props.font ?? `"Barlow"`}, Helvetica;
    font-size: ${(props) => props.fsize};
    font-weight: ${(props) => props.fweight ?? "700"};
    left: ${(props) => props.left ?? "0px"};
    letter-spacing: 0;
    line-height: ${(props) => props.linehg ?? props.fsize};
    position: absolute;
    top: ${(props) => props.top};
    text-align: ${(props) => props.align ?? ""};
`;

export const TheBuildingDescriptionWrapper = styled.div`
    color: ${(props) => props.color ?? "#2c53af"};
    font-family: ${(props) => props.font ?? "SegoeUI-Regular"}, Helvetica;
    font-size: ${(props) => props.fsize ?? "21px"};
    font-weight: ${(props) => props.fweight ?? "600"};
    left: ${(props) => props.left ?? "0"};
    letter-spacing: 0;
    line-height: ${(props) => props.linehg ?? "21px"};
    position: absolute;
    text-align: ${(props) => props.align ?? ""};
    top: ${(props) => props.top ?? "39px"};
    width: ${(props) => props.width ?? "295px"};
`;

export const TheBuildingImage = styled.img`
    height: ${(props) => props.height ?? "auto"};
    object-fit: ${(props) => props.fit ?? "cover"};
    width: ${(props) => props.width ?? "100%"};
    border: ${(props) => props.border ?? ""};
    margin: ${(props) => props.margin ?? ""};

    // transform: ${(props) => (props.isActive ? "translateX(0)" : "translateX(100%)")};
`;

// #region Key Features section

export const KeyFeatureIcon = styled.img`
    cursor: pointer;
    transition: transform 0.3s ease;
    object-fit: contain;
    height: ${(props) => props.height ?? "100px"};
    width: ${(props) => props.width ?? "100px"};
`;

export const AmenitiesIcon = styled.img`
    cursor: pointer;
    transition: transform 0.3s ease;
    object-fit: contain;
    position: ${(props) => props.position ?? "absolute"};
    height: ${(props) => props.height ?? "90px"};
    width: ${(props) => props.width ?? "90px"};
    top: ${(props) => props.top ?? "0px"};
    left: ${(props) => props.left ?? "0px"};
`;

export const SustainabilityIcon = styled.img`
    cursor: pointer;
    transition: transform 0.3s ease;
    object-fit: contain;
    height: ${(props) => props.height ?? "90px"};
    width: ${(props) => props.width ?? "90px"};
`;

export const HoverImageZoom = styled.div`
    &:hover .key-feature-icon {
        transform: scale(1.25);
        animation: none;
    }
    &:hover .amenities-icon {
        transform: scale(1.25);
        animation: none;
    }
    &:hover .sustainability-icon {
        transform: scale(1.25);
        animation: none;
    }
`;

export const HoverImageMobile = styled.div`
    &:hover .key-feature-icon {
        transform: scale(1.15);
        animation: none;
    }
    &:hover .amenities-icon {
        transform: scale(1.15);
        animation: none;
    }
    &:hover .sustainability-icon {
        transform: scale(1.15);
        animation: none;
    }
    @media (max-width: 767px) {
        grid-column: ${(props) => props.gridcolumn};
    }
`;

export const KeyFeaturesSectionWrapper = styled.div`
    display: block;
`;

export const SelectionWrapper = styled.div`
    height: ${(props) => props.height ?? "156px"};
    left: 0;
    top: 0;
    width: ${(props) => props.width ?? "758px"};
    display: flex;
    justify-content: center;
`;

export const KeyFeaturesSectionContent = styled.div`
    height: 846px;
    left: 82px;
    position: absolute;
    top: 3780px;
    width: 1284px;
`;

export const KeyFeatureSectionTitle = styled.div`
    color: #353535;
    font-family: "Barlow", Helvetica;
    font-size: 50px;
    font-weight: 700;
    left: 485px;
    letter-spacing: 0;
    line-height: 50px;
    position: absolute;
    top: 8px;
    white-space: nowrap;
`;

export const DescriptionWrapper = styled.div`
    height: ${(props) => props.height ?? "562px"};
    left: ${(props) => props.left ?? "0"};
    position: absolute;
    top: ${(props) => props.top ?? "200px"};
    width: ${(props) => props.width ?? "1284px"};
`;

export const SelectedIcon = styled.div`
    display: block;
`;

export const DescriptionImage = styled.img`
    height: ${(props) => props.height ?? "562px"};
    left: ${(props) => props.left ?? "0"};
    object-fit: cover;
    position: absolute;
    top: ${(props) => props.top ?? "0"};
    width: 779px;
    border: 1px solid #000;
`;

export const Description = styled.div`
    height: ${(props) => props.height ?? "562px"};
    left: ${(props) => props.left ?? "0"};
    position: absolute;
    top: ${(props) => props.top ?? "0"};
    width: ${(props) => props.width ?? "472px"};
`;

export const DescriptionTitle = styled.div`
    color: ${(props) => props.color ?? "#2c53af"};
    font-family: "Barlow", Helvetica;
    font-size: ${(props) => props.fontSize ?? "30px"};
    font-weight: 600;
    left: 0;
    letter-spacing: 0;
    line-height: ${(props) => props.linehg ?? "30px"};
    position: absolute;
    text-align: center;
    top: ${(props) => props.top ?? "30px"};
    width: ${(props) => props.width ?? "454px"};
`;
export const DescriptionContent = styled.div`
    color: #353535;
    font-family: ${(props) => props.fontFamily ?? "SegoeUI-Regular"}, Helvetica;
    font-size: ${(props) => props.fsize ?? "21px"};
    font-weight: 400;
    left: ${(props) => props.left ?? "0"};
    letter-spacing: 0;
    line-height: ${(props) => props.linehg ?? "32px"};
    position: absolute;
    top: ${(props) => props.top ?? "158px"};
    width: ${(props) => props.width ?? "472px"};
`;
// #endregion

// #region Mobile
export const HeroLanding = styled.div`
    background-image: url(./img/${(props) => props.bgurl});
    background-position: 50% 50%;
    background-size: cover;
    width: 100%;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;

    @media (max-width: 1366px) and (min-width: 1281px) {
        height: 768px;
    }

    @media (max-width: 1280px) {
        height: 100vh;
    }

    @media screen and (min-width: 1367px) {
        height: 100vh;
    }
`;

export const TheBuildingDisplay = styled.div`
    display: flex;
    flex-direction: ${(props) => props.direction ?? "column"};
    justify-content: ${(props) => props.justify ?? ""};
    align-items: ${(props) => props.align ?? ""};
    padding: ${(props) => props.padding ?? ""};
    margin: ${(props) => props.margin ?? ""};
    height: ${(props) => props.height ?? ""};
    width: ${(props) => props.width ?? ""};
    @media (min-width: 1280px) and (max-width: 1365px) {
        margin-left: 30px;
    }
`;

export const TheBuildingGrid = styled.div`
    display: grid;
    grid-template-columns: ${(props) => props.template ?? "auto auto auto"};
    row-gap: ${(props) => props.rwgap ?? "20px"};
    column-gap: ${(props) => props.colgap ?? "20px"};
    padding: ${(props) => props.padding ?? "0px 20px"};
    text-align: ${(props) => props.align ?? "center"};

    @media (min-width: 768px) and (max-width: 1279px) {
        grid-template-columns: ${(props) => props.tablet ?? "12% 12% 12% 12% 12% 12% 12%"};
        justify-content: center;
        row-gap: "";
        column-gap: "";
        padding: "";
        gap: 20px;
    }
`;

export const TheBuildingGridIcon = styled.div`
    display: grid;
    grid-template-columns: ${(props) => props.template ?? "120px 120px 120px 120px 120px 120px 120px"};
    gap: ${(props) => props.gap ?? "30px"};
    padding: ${(props) => props.padding ?? ""};
    text-align: ${(props) => props.align ?? ""};
`;

export const TheBuildingDisplayAlignItems = styled.div`
    display: flex;
    flex-direction: ${(props) => props.direction ?? "column"};
    align-items: center;
    margin: ${(props) => props.margin ?? ""};
`;

export const TheBuildingDisplayMargin = styled.div`
    display: flex;
    flex-direction: ${(props) => props.direction ?? "column"};
    margin: ${(props) => props.margin ?? "110px 0px 0px 0px"};

    @media (min-width: 481px) {
        margin: "10px 0px 0px 0px";
    }
`;

export const TheBuildingDivContent = styled.div`
    padding: ${(props) => props.padding ?? ""};
`;

export const TheBuildingJustifyContent = styled.div`
    height: ${(props) => props.height ?? "auto"};
    width: ${(props) => props.width ?? "100%"};
    display: ${(props) => props.display ?? "flex"};
    flex-direction: ${(props) => props.direction ?? ""};
    justify-content: ${(props) => props.justify ?? "center"};
    align-items: ${(props) => props.align ?? ""};
    padding: ${(props) => props.padding ?? ""};
    gap: ${(props) => props.gap ?? ""};
`;

export const TheBuildingTitle = styled.div`
    width: ${(props) => props.width ?? ""};
    color: ${(props) => props.color ?? "#353535"};
    letter-spacing: 0;
    text-align: ${(props) => props.align ?? "center"};
    font-family: ${(props) => (props.languagecode === "jp" ? "'HiraKaku', sans-serif" : props.font ?? "'Segoe UI', Helvetica")};
    font-size: ${(props) => props.fsize ?? "16px"};
    font-weight: ${(props) => props.fweight ?? "700"};
    line-height: ${(props) => props.linehg ?? "16px"};
    white-space: ${(props) => props.wspace ?? ""};
    text-shadow: ${(props) => props.shadow ?? ""};
    margin-top: ${(props) => props.mgtop ?? ""};
    padding: ${(props) => props.padding ?? ""};

    @media (min-width: 768px) and (max-width: 1279px) {
        font-size: 67px;
        line-height: 79px;
    }
`;

export const ParagraphText = styled.div`
    text-align: ${(props) => props.align ?? "justify"};
    font-weight: ${(props) => props.fweight};
    padding-top: ${(props) => props.paddingtop};
`;

export const TheBuildingParagraphs = styled.div`
    width: ${(props) => props.width ?? ""};
    color: ${(props) => props.color ?? "#353535"};
    text-align: ${(props) => props.align ?? "center"};
    text-align-last: ${(props) => props.last};
    letter-spacing: 0;
    font-family: ${(props) => (props.languagecode === "jp" ? "'Barlow', Helvetica" : props.font ?? "'Segoe UI', Helvetica")};
    font-size: ${(props) => props.fsize ?? "13px"};
    font-weight: ${(props) => props.fweight ?? "700"};
    line-height: ${(props) => props.linehg ?? 1.25};
    white-space: ${(props) => props.wspace ?? ""};
    text-shadow: ${(props) => props.shadow ?? ""};
    margin-top: ${(props) => props.mgtop ?? ""};
    padding: ${(props) => props.padding ?? ""};
    cursor: ${(props) => props.cursor ?? ""};

    @media (min-width: 768px) and (max-width: 1279px) {
        font-size: ${(props) => props.tablet ?? "47px"};
        line-height: ${(props) => (props.tablet ? "27px" : 1.15)};
    }
`;

export const TheBuildingButtonContact = styled.div`
    width: 150px;
    height: ${(props) => props.height ?? "40px"};
    margin: 20px 0px;
`;
// #endregion
