{
    "Overview": "概要",
    "SubOverview": "概要",
    "Concept": "コンセプト",
    "Location": "ロケーション",
    "The Building": "建物概要",
    "Key Features": "主な特徴",
    "Amenities": "施設アメニティ",
    "Sustainability": "サステナビリティ",
    "Leasing": "リーシング",
    "Office": "オフィス",
    "Retail": "店舗",
    "Virtual Tour": "バーチャル・ツアー",
    "360Tour": "360度ツアー",
    "Photo Gallery": "フォト・ギャラリー",
    "Developer": "会社紹介",
    "About Us": "会社紹介",
    "SubDeveloper": "事業主体",
    "Investor": "出資者",
    "Partners": "パートナー",
    "Media": "メディア",
    "News": "ニュース",
    "Leasing Events": "リーシング・イベント",
    "Press": "プレス", 
    "Contact": "お問合せ",
    "Disclaimer": "免責条項",
    "ContentFooter": "ここに含まれる情報の正確性に関して細心の注意を払って作成していますが、弊社はいかなる保証や法的な表明を行うものではありません。利害関係者は、正式な取引文書を参照してください。図面、イラスト、写真、パースなどの視覚的表現は、芸術的な印象を描写するものであり、事実や法的な表現として解釈されるべきではありません。仕様、単位、計画の全部またはその一部を変更することがあります。"
}
