import React, { useRef, useEffect } from "react";
import { TextAnimation, SectionAnimation } from "../../../breakpoints";
import { animated } from "@react-spring/web";
import { useInView } from "react-intersection-observer";
import ContactUsButtonComponent from "../../../components/ContactUsButton";
import { Link } from "react-router-dom";
import { LocationSectionWrapper, LocationWrapper, OverviewDisplay, OverviewDisplayJustify, OverviewParagraphs, OverviewImage, OverviewGrid, OverviewBuildingProperty, OverviewBuildingPropertyDescription, OverviewBuildingPropertyImage, SpanWrapper, OverviewGroupContent } from "../../../style/OverviewScreen";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import ReactGA from "react-ga4";

const NearestPlaces = () => {
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);
    return (
        <OverviewGrid template={"225px 225px 225px 225px"} gap={"20px"}>
            <OverviewBuildingProperty>
                <OverviewGroupContent>
                    <OverviewBuildingPropertyImage src="./img/airport.png" height="80px" width="80px" />
                </OverviewGroupContent>
                <OverviewBuildingPropertyDescription languagecode={currentLanguageCode} color={"#353535"} font={"'Segoe UI', Helvetica"} fsize={"22px"} fweight={"700"} linehg={"22px"}>
                    <SpanWrapper languagecode={currentLanguageCode} font={"'Barlow', Helvetica"} fweight="700">
                        {t("30 MINS")}
                        <br />
                    </SpanWrapper>
                    <SpanWrapper languagecode={currentLanguageCode} font={"'Barlow', Helvetica"} fsize="16px">
                        {parse(t("NOI BAI INTERNATIONAL AIRPORT"))}
                    </SpanWrapper>
                </OverviewBuildingPropertyDescription>
            </OverviewBuildingProperty>

            <OverviewBuildingProperty>
                <OverviewGroupContent>
                    <OverviewBuildingPropertyImage src="./img/hoankiem.png" height="80px" width="80px" />
                </OverviewGroupContent>
                <OverviewBuildingPropertyDescription languagecode={currentLanguageCode} color={"#353535"} font={"'Segoe UI', Helvetica"} fsize={"22px"} fweight={"700"} linehg={"22px"}>
                    <SpanWrapper languagecode={currentLanguageCode} font={"'Barlow', Helvetica"} fweight="700">
                        {t("30 MINS")}
                        <br />
                    </SpanWrapper>
                    <SpanWrapper languagecode={currentLanguageCode} font={"'Barlow', Helvetica"} fsize="16px">
                        {parse(t("HOAN KIEM DISTRICT"))}
                    </SpanWrapper>
                </OverviewBuildingPropertyDescription>
            </OverviewBuildingProperty>

            <OverviewBuildingProperty>
                <OverviewGroupContent>
                    <OverviewBuildingPropertyImage src="./img/badinh.png" height="80px" width="80px" />
                </OverviewGroupContent>
                <OverviewBuildingPropertyDescription languagecode={currentLanguageCode} color={"#353535"} font={"'Segoe UI', Helvetica"} fsize={"22px"} fweight={"700"} linehg={"22px"}>
                    <SpanWrapper languagecode={currentLanguageCode} font={"'Barlow', Helvetica"} fweight="700">
                        {t("15 MINS")}
                        <br />
                    </SpanWrapper>
                    <SpanWrapper languagecode={currentLanguageCode} font={"'Barlow', Helvetica"} fsize="16px">
                        {parse(t("DONG DA AND BA DINH DISTRICT"))}
                    </SpanWrapper>
                </OverviewBuildingPropertyDescription>
            </OverviewBuildingProperty>

            <OverviewBuildingProperty>
                <OverviewGroupContent height="80px" width="80px">
                    <OverviewBuildingPropertyImage src="./img/highway.png" height="70px" width="70px" />
                </OverviewGroupContent>
                <OverviewBuildingPropertyDescription languagecode={currentLanguageCode} color={"#353535"} font={"'Segoe UI', Helvetica"} fsize={"22px"} fweight={"700"} linehg={"22px"}>
                    <SpanWrapper languagecode={currentLanguageCode} font={"'Barlow', Helvetica"} fweight="700">
                        {t("3 MINS")}
                        <br />
                    </SpanWrapper>
                    <SpanWrapper languagecode={currentLanguageCode} font={"'Barlow', Helvetica"} fsize="16px">
                        {parse(t("LANG HOA LAC HIGHWAY"))}
                    </SpanWrapper>
                </OverviewBuildingPropertyDescription>
            </OverviewBuildingProperty>
        </OverviewGrid>
    );
};

const LocationSection = ({ onHeightChange }) => {
    const sectionRef = useRef(null);
    const [inViewRef, inView] = useInView();
    const sectionSpring = SectionAnimation(inView);
    const descriptionSpring = TextAnimation(inView);
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    const LinkClickedHandler = () => {
        ReactGA.event({
            category: "Click",
            action: "Click",
            label: "Location map taisei"
        });

        window.open("https://www.google.com/maps/place/Taisei+Square+Hanoi/@21.002469,105.793777,17z/data=!4m6!3m5!1s0x3135ad460ef012e1:0x73874de77f3e7407!8m2!3d21.0024691!4d105.7937768!16s%2Fg%2F11v60qvzf6?hl=en&entry=ttu", "_blank", "noopener");
    };

    useEffect(() => {
        const sectionHeight = sectionRef.current ? sectionRef.current.clientHeight : 0;
        onHeightChange((prevHeight) => prevHeight + sectionHeight);
    }, [onHeightChange]);

    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);

    return (
        <LocationSectionWrapper id="location" height="auto" position="relative" ref={sectionRef}>
            <LocationWrapper ref={inViewRef} style={sectionSpring}>
                <OverviewDisplay direction={"row"} margin={"82px 0 60px 82px"}>
                    <OverviewDisplayJustify direction={"column"} width={"40%"} justify={"start"} align={"flex-start"}>
                        <OverviewParagraphs languagecode={currentLanguageCode} font={"Barlow, Helvetica"} fsize={"47px"} linehg={"47px"}>
                            <animated.div style={descriptionSpring}>{t("LOCATION")}</animated.div>
                        </OverviewParagraphs>

                        <OverviewParagraphs languagecode={currentLanguageCode} padding={"30px 35px 0 0 "} align={"left"} fweight={"400"} fsize={"16px"} linehg={"24px"}>
                            <animated.div style={descriptionSpring}>
                                {t("LOCATION DESCRIPTION")}
                                <OverviewDisplayJustify padding={"30px 0px"}>
                                    <Link to="/contact">
                                        <ContactUsButtonComponent text={t("CONTACT US")} />
                                    </Link>
                                </OverviewDisplayJustify>
                            </animated.div>
                        </OverviewParagraphs>
                    </OverviewDisplayJustify>
                    <OverviewDisplayJustify width={"60%"}>
                        <OverviewImage height="500px" width="779px" cursor={"pointer"} src="./img/location-map.png" onClick={LinkClickedHandler} />
                    </OverviewDisplayJustify>
                </OverviewDisplay>
                <NearestPlaces />
            </LocationWrapper>
        </LocationSectionWrapper>
    );
};

LocationSection.propTypes = {
    onHeightChange: PropTypes.func
};

export default LocationSection;
