import ContactUsButtonComponent from "../../../components/ContactUsButton";
import { Link } from "react-router-dom";
import { ContactDisplay, HeroLanding, ContactDivContent, ContactParagraphsHero, ContactButtonContact } from "../../../style/ContactScreen";
import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const HeroSection = ({ onHeightChange }) => {
    const sectionRef = useRef(null);
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);

    useEffect(() => {
        const sectionHeight = sectionRef.current ? sectionRef.current.clientHeight : 0;
        onHeightChange(sectionHeight);
    }, [onHeightChange]);

    return (
        <HeroLanding id="hero" ref={sectionRef} bgurl={"7-hero.png"}>
            <ContactDisplay>
                <ContactDivContent padding={"70px 0px 10px 0px"}>
                    <ContactParagraphsHero languagecode={currentLanguageCode} color={"#f4f4f4"} fsize={"68px"} linehg={"68px"} fweight={"600"} shadow={"3px 3px 6px #353535"}>
                        {t("REACH OUT TO US")}
                    </ContactParagraphsHero>
                </ContactDivContent>
                <ContactButtonContact>
                    <Link to="/overview">
                        <ContactUsButtonComponent text={"VISIT US"} color={"#F4F4F4"} textml={"40px"} hovered hidden={true} />
                    </Link>
                </ContactButtonContact>
            </ContactDisplay>
        </HeroLanding>
    );
};

HeroSection.propTypes = {
    onHeightChange: PropTypes.func
};

export default HeroSection;
