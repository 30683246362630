export const policies = [
    {
        id: "en",
        content: `
        <p class="MsoNormal" style="text-align:justify; line-height: 24px; font-size: 16px; font-family: Segeo UI, Helvetica;">
            <p class="MsoNormal" style="text-align:justify;margin: 0in 0in 13px; line-height: 24px; font-size: 16px; font-family: Segeo UI, Helvetica;"><b>Privacy Policy</b></p>
            <p class="MsoNormal" style="text-align:justify;margin: 0in 0in 13px; line-height: 24px; font-size: 16px; font-family: Segeo UI, Helvetica;">Last updated: September 21, 2023</p>
            <p class="MsoNormal" style="text-align:justify;margin: 0in 0in 13px; font-size: 16px; font-family: Segeo UI, Helvetica;">This Privacy Policy describes Our policies and procedures on the collection, use, processing, and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
            <p class="MsoNormal" style="text-align:justify;margin: 0in 0in 13px; line-height: 24px; font-size: 16px; font-family: Segeo UI, Helvetica;">We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection, use, processing and disclosure of Your information in accordance with this Privacy Policy.</p>
            <h2 style="text-align:justify; font-weight:800;margin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 17px; font-family: Segeo UI, Helvetica; color: black;">Interpretation and Definitions</h2>
            <p class="MsoNormal" style="text-align:justify;margin: 0in 0in 13px; line-height: 24px; font-size: 16px; font-family: Segeo UI, Helvetica;">
            <h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 16px; font-family: Segeo UI, Helvetica; color: black;">Interpretation</h3>
           The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
            <br><h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 16px; font-family: Segeo UI, Helvetica; color: black;">Definitions</h3>
            <p class="MsoNormal" style="text-align:justify;margin: 0in 0in 13px; line-height: 24px; font-size: 16px; font-family: Segeo UI, Helvetica;">For the purposes of this Privacy Policy:</p>
            <ul >
            <p style="text-align:justify; margin: 0in 0in 0in 0.5in; font-size: 16px; font-family: Segeo UI, Helvetica;"">
<b>Affiliate</b> means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</li>
<br><br><b>Company</b> (referred to as either "the Company", "We", "Us" or "Our" in this Policy) refers to Taisei Development Hanoi Co Ltd, 289 Khuat Duy Tien, Trung Hoa, Cau Giay, Hanoi.</li>
<br><br><b>Cookies</b> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</li>
<br><br><b>Country</b> refers to: Vietnam</li>
<br><br><b>Device</b> means any device that can access the Service such as a computer, a cellphone, or a digital tablet.</li>
<br><br><b>Personal Data</b> means information in the form of symbol, script, digit, image, or sound or in a similar form in the electronic environment which is affiliated to a specific person or helps identify a specific person.</li>
<br><br><b>Project</b> is Taisei Square Hanoi, an office building located at 289 Khuat Duy Tien street, Trung Hoa ward, Cau Giay district, Hanoi.</li>
<br><br><b>Service</b> refers to the Website.</li>
<br><br><b>Service Provider</b> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</li>
<br><br><b>Usage Data</b> refers to data collected automatically, either generated using the Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>
<br><br><b>Website</b> refers to Taisei Square Hanoi, accessible from <a href="http://www.taiseisquarehanoi.com">http://www.taiseisquarehanoi.com</a></li>
<br><br><b>You</b> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
          </p>
                </ul>
            <h2 style="text-align:justify; font-weight:800;margin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 17px; font-family: Segeo UI, Helvetica; color: black;">Collecting, Using and Processing Your Personal Data</h2>
            <h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 16px; font-family: Segeo UI, Helvetica; color: black;"><i>Types of Data Collected</i></h3>
            <h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 16px; font-family: Segeo UI, Helvetica; color: black;">Personal Data</h3>
            
            <p class="MsoNormal" style="text-align:justify;margin: 0in 0in 13px; line-height: 24px; font-size: 16px; font-family: Segeo UI, Helvetica;">While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
            <p style="text-align:justify;margin: 0in 0in 0in 0.5in; line-height: 24px; font-size: 16px; font-family: Segeo UI, Helvetica;">
                Email address
                <br>First name and last name
                <br>Phone number</li>
                <br>Address, State, Province, ZIP/Postal code, City
                <br>Usage Data
            </p>
            <h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 16px; font-family: Segeo UI, Helvetica; color: black;">Usage Data</h3>
            <p style="text-align:justify;margin: 0in 0in 0in 0.5in;font-size: 16px; font-family: Segeo UI, Helvetica;">Usage Data is collected automatically when using the Service.<br>
<span>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
</span><br><br>When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.
<span><br><br>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.
</span>
 </p>

</p>
<h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 16px; font-family: Segeo UI, Helvetica; color: black;">
Tracking Technologies and Cookies
</h3>
<p style="text-align:justify; font-size: 16px; font-family: Segeo UI, Helvetica;">
We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:

<p style="text-align:justify;margin: 0in 0in 0in 0.5in;  font-size: 16px; font-family: Segeo UI, Helvetica;"><br> <bold>⦁  Cookies or Browser Cookies.</bold> A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.<br>
<br>  <bold>⦁  Web Beacons.</bold> Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).
</p></p>
<br>
<p style="text-align:justify;  font-size: 16px; font-family: Segeo UI, Helvetica;">Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser.
<br>
We use both Session and Persistent Cookies for the purposes set out below:</p>
<h3 style="text-align:justify; font-weight:800;ckmargin: 0in 0in 0in 0.5in; line-height: 24px; break-after: avoid; font-size: 16px; font-family: Segeo UI, Helvetica; color: black;">Necessary / Essential Cookies
</h3>
<p style="text-align:justify;margin: 0in 0in 0in 0.5in; line-height: 24px; font-size: 16px; font-family: Segeo UI, Helvetica;">
Type: Session Cookies
<br>
Administered by: Us
<br>
Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.
</p>
<h3 style="text-align:justify; font-weight:800;ckmargin: 0in 0in 0in 0.5in; line-height: 24px; break-after: avoid; font-size: 16px; font-family: Segeo UI, Helvetica; color: black;">Cookies Policy / Notice Acceptance Cookies
</h3>
<p style="text-align:justify;margin: 0in 0in 0in 0.5in; line-height: 24px; font-size: 16px; font-family: Segeo UI, Helvetica;">
Type: Persistent Cookies
<br>
Administered by: Us
<br>
Purpose: These Cookies identify if users have accepted the use of cookies on the Website.
<br><b>Functionality Cookies</b>
<br>Type: Persistent Cookies
<br>Administered by: Us
</p>

<h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 16px; font-family: Segeo UI, Helvetica; color: black;">
Purpose
</h3>
<p style="text-align:justify; line-height: 24px; font-size: 16px; font-family: Segeo UI, Helvetica;">These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.</p>
<h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 16px; font-family: Segeo UI, Helvetica; color: black;">
Use and Processing of Your Personal Data
</h3>
The Company may use and process Personal Data for the following purposes:
<br><br>
<p style="text-align:justify;margin: 0in 0in 0in 0.5in;  font-size: 16px; font-family: Segeo UI, Helvetica;">
<b>To provide and maintain our Service </b>, including to monitor the usage of our Service. <br><br>
<b>To contact You:</b> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication regarding updates or informative communications related to the Project or the Service, including the security updates, when necessary or reasonable for their implementation.
<br><br><b>To provide You</b> with news, special offers, events and general information regarding the Project or the Service.
<br><br><b>To manage Your requests:</b> To attend and manage Your requests to Us.
<br><br><b>For business transfers:</b> We may use and process Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.
<br><br><b>For other purposes:</b> We may use and process Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our services, marketing and your experience.
</p>
<br>
<p style="text-align:justify;font-size: 16px; font-family: Segeo UI, Helvetica;">We may share Your personal information in the following situations:</p>
<br>
<p style="text-align:justify;line-height: 24px;margin: 0in 0in 0in 0.5in; ">
<b>  With Service Providers</b>: We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.
<br><br>  	<b>For business transfers:</b> We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.
<br><br>  	<b>With Affiliates:</b> We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.
<br><br>  	<b>With business partners:</b> We may share Your information with Our business partners to offer You certain services or promotions.
<br><br>  	<b>With other users:</b> when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.
<br><br>  	<b>With competent authorities</b> to whom the Company is obligated or required to disclose according to legal regulations or requirement of the governmental authorities.
<br><br>   <b>With Your consent:</b> We may disclose Your personal information for any other purpose with Your consent.
</p>
<br>
<h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 17px; font-family: Segeo UI, Helvetica; color: black;">
Retention of Your Personal Data
</h3>
The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
<br><br>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.
<h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 17px; font-family: Segeo UI, Helvetica; color: black;">
Transfer of Your Personal Data
</h3>
Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country, or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.
<br><br>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.
<br><br>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.
<h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 17px; font-family: Segeo UI, Helvetica; color: black;">
Delete Your Personal Data
</h3>
You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You.
<br>
Our Service may give You the ability to delete certain information about You from within the Service.
<br>You may also contact Us to request access to, correct, or delete any personal information that You have provided to Us.
<br>Please note, however, that We may need to retain certain information when we have a legal obligation or lawful basis to do so.
<br><br>
<h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 17px; font-family: Segeo UI, Helvetica; color: black;">
Disclosure of Your Personal Data
</h3>
<h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 17px; font-family: Segeo UI, Helvetica; color: black;">
Business Transactions
</h3>
If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.
<h2 style="text-align:justify; font-weight:800;margin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 17px; font-family: Segeo UI, Helvetica; color: black;">
Law enforcement
</h2>
Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).
<h2 style="text-align:justify; font-weight:800;margin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 17px; font-family: Segeo UI, Helvetica; color: black;">
Other legal requirements
</h2>
The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:
<p style="text-align:justify;line-height: 24px">
⦁	Comply with a legal obligation.
<br>
	Protect and defend the rights or property of the Company.
<br>
⦁	Prevent or investigate possible wrongdoing in connection with the Service.
<br>
⦁	Protect the personal safety of Users of the Service or the public.
<br>
⦁	Protect against legal liability.
</p>
<br>
<h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 17px; font-family: Segeo UI, Helvetica; color: black;">
Security of Your Personal Data
</h3>
The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.
<br><br><h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 17px; font-family: Segeo UI, Helvetica; color: black;">
Your Rights and Obligations regarding Your Personal Data
</h3>
You have the rights and obligations in respect of Your Personal Data in accordance with the applicable laws and regulations of Vietnam.
<h2 style="text-align:justify; font-weight:800;margin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 17px; font-family: Segeo UI, Helvetica; color: black;">
Children's Privacy
</h2>
Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.
<br>
<br>
If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.
<h2 style="text-align:justify; font-weight:800;margin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 17px; font-family: Segeo UI, Helvetica; color: black;">
Links to Other Websites
</h2>
Our Service may contain links to other websites that are not operated by Us. If You click on a third-party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.
<br>We have no control over and assume no responsibility for the content, privacy policies or practices of any third-party sites or services.
<br><br><h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 17px; font-family: Segeo UI, Helvetica; color: black;">
Changes to this Privacy Policy
</h3>
<p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.
<br><br>We will let You know via a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.
<br><br>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
<br><br>We may update our Privacy Policy from time to time. Your continued access to the Service and/or maintenance of relationships, interactions, and exchanges with the Company after we have posted our updated Privacy Policy on Our Service constitutes your acceptance of the updated Privacy Policy.
</p>
<br><br><h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 17px; font-family: Segeo UI, Helvetica; color: black;">
Contact Us
</h3>
<p style="text-align:justify;line-height: 24px">
If you have any questions about this Privacy Policy, You can contact us:

<p style="text-align:justify;margin: 0in 0in 0in 0.5in; line-height:  24px; font-size: 16px; font-family: Segeo UI, Helvetica;">
By email: inquiry@taiseidh.vn
<br>By phone number: +84888247289
</p></p>
</p>
 `
    },
    {
        id: "jp",
        content: `
        <p class="MsoNormal" style="text-align:justify; line-height: 24px; font-size: 16px; font-family: Segeo UI, Helvetica;">
            <p class="MsoNormal" style="text-align:justify;margin: 0in 0in 13px; line-height: 24px; font-size: 16px; font-family: Segeo UI, Helvetica;"><b>Privacy Policy</b></p>
            <p class="MsoNormal" style="text-align:justify;margin: 0in 0in 13px; line-height: 24px; font-size: 16px; font-family: Segeo UI, Helvetica;">Last updated: September 21, 2023</p>
            <p class="MsoNormal" style="text-align:justify;margin: 0in 0in 13px; font-size: 16px; font-family: Segeo UI, Helvetica;">This Privacy Policy describes Our policies and procedures on the collection, use, processing, and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
            <p class="MsoNormal" style="text-align:justify;margin: 0in 0in 13px; line-height: 24px; font-size: 16px; font-family: Segeo UI, Helvetica;">We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection, use, processing and disclosure of Your information in accordance with this Privacy Policy.</p>
            <h2 style="text-align:justify; font-weight:800;margin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 17px; font-family: Segeo UI, Helvetica; color: black;">Interpretation and Definitions</h2>
            <p class="MsoNormal" style="text-align:justify;margin: 0in 0in 13px; line-height: 24px; font-size: 16px; font-family: Segeo UI, Helvetica;">
            <h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 16px; font-family: Segeo UI, Helvetica; color: black;">Interpretation</h3>
           The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
            <br><h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 16px; font-family: Segeo UI, Helvetica; color: black;">Definitions</h3>
            <p class="MsoNormal" style="text-align:justify;margin: 0in 0in 13px; line-height: 24px; font-size: 16px; font-family: Segeo UI, Helvetica;">For the purposes of this Privacy Policy:</p>
            <ul >
            <p style="text-align:justify; margin: 0in 0in 0in 0.5in; font-size: 16px; font-family: Segeo UI, Helvetica;"">
<b>Affiliate</b> means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</li>
<br><br><b>Company</b> (referred to as either "the Company", "We", "Us" or "Our" in this Policy) refers to Taisei Development Hanoi Co Ltd, 289 Khuat Duy Tien, Trung Hoa, Cau Giay, Hanoi.</li>
<br><br><b>Cookies</b> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</li>
<br><br><b>Country</b> refers to: Vietnam</li>
<br><br><b>Device</b> means any device that can access the Service such as a computer, a cellphone, or a digital tablet.</li>
<br><br><b>Personal Data</b> means information in the form of symbol, script, digit, image, or sound or in a similar form in the electronic environment which is affiliated to a specific person or helps identify a specific person.</li>
<br><br><b>Project</b> is Taisei Square Hanoi, an office building located at 289 Khuat Duy Tien street, Trung Hoa ward, Cau Giay district, Hanoi.</li>
<br><br><b>Service</b> refers to the Website.</li>
<br><br><b>Service Provider</b> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</li>
<br><br><b>Usage Data</b> refers to data collected automatically, either generated using the Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>
<br><br><b>Website</b> refers to Taisei Square Hanoi, accessible from <a href="http://www.taiseisquarehanoi.com">http://www.taiseisquarehanoi.com</a></li>
<br><br><b>You</b> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
          </p>
                </ul>
            <h2 style="text-align:justify; font-weight:800;margin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 17px; font-family: Segeo UI, Helvetica; color: black;">Collecting, Using and Processing Your Personal Data</h2>
            <h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 16px; font-family: Segeo UI, Helvetica; color: black;"><i>Types of Data Collected</i></h3>
            <h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 16px; font-family: Segeo UI, Helvetica; color: black;">Personal Data</h3>
            
            <p class="MsoNormal" style="text-align:justify;margin: 0in 0in 13px; line-height: 24px; font-size: 16px; font-family: Segeo UI, Helvetica;">While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
            <p style="text-align:justify;margin: 0in 0in 0in 0.5in; line-height: 24px; font-size: 16px; font-family: Segeo UI, Helvetica;">
                Email address
                <br>First name and last name
                <br>Phone number</li>
                <br>Address, State, Province, ZIP/Postal code, City
                <br>Usage Data
            </p>
            <h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 16px; font-family: Segeo UI, Helvetica; color: black;">Usage Data</h3>
            <p style="text-align:justify;margin: 0in 0in 0in 0.5in;font-size: 16px; font-family: Segeo UI, Helvetica;">Usage Data is collected automatically when using the Service.<br>
<span>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
</span><br><br>When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.
<span><br><br>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.
</span>
 </p>

</p>
<h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 16px; font-family: Segeo UI, Helvetica; color: black;">
Tracking Technologies and Cookies
</h3>
<p style="text-align:justify; font-size: 16px; font-family: Segeo UI, Helvetica;">
We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:

<p style="text-align:justify;margin: 0in 0in 0in 0.5in;  font-size: 16px; font-family: Segeo UI, Helvetica;"><br> <bold>⦁  Cookies or Browser Cookies.</bold> A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.<br>
<br>  <bold>⦁  Web Beacons.</bold> Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).
</p>
<p>Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser.</p>
<br>
We use both Session and Persistent Cookies for the purposes set out below:
<h3 style="text-align:justify; font-weight:800;ckmargin: 0in 0in 0in 0.5in; line-height: 24px; break-after: avoid; font-size: 16px; font-family: Segeo UI, Helvetica; color: black;">Necessary / Essential Cookies
</h3>
<p style="text-align:justify;margin: 0in 0in 0in 0.5in; line-height: 24px; font-size: 16px; font-family: Segeo UI, Helvetica;">
Type: Session Cookies
<br>
Administered by: Us
<br>
Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.
</p>
<h3 style="text-align:justify; font-weight:800;ckmargin: 0in 0in 0in 0.5in; line-height: 24px; break-after: avoid; font-size: 16px; font-family: Segeo UI, Helvetica; color: black;">Cookies Policy / Notice Acceptance Cookies
</h3>
<p style="text-align:justify;margin: 0in 0in 0in 0.5in; line-height: 24px; font-size: 16px; font-family: Segeo UI, Helvetica;">
Type: Persistent Cookies
<br>
Administered by: Us
<br>
Purpose: These Cookies identify if users have accepted the use of cookies on the Website.
<br><b>Functionality Cookies</b>
<br>Type: Persistent Cookies
<br>Administered by: Us
</p>

<h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 16px; font-family: Segeo UI, Helvetica; color: black;">
Purpose
</h3>
<p style="text-align:justify; line-height: 24px; font-size: 16px; font-family: Segeo UI, Helvetica;">These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.</p>
<h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 16px; font-family: Segeo UI, Helvetica; color: black;">
Use and Processing of Your Personal Data
</h3>
The Company may use and process Personal Data for the following purposes:
<br><br>
<p style="text-align:justify;margin: 0in 0in 0in 0.5in;  font-size: 16px; font-family: Segeo UI, Helvetica;">
<b>To provide and maintain our Service </b>, including to monitor the usage of our Service. <br><br>
<b>To contact You:</b> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication regarding updates or informative communications related to the Project or the Service, including the security updates, when necessary or reasonable for their implementation.
<br><br><b>To provide You</b> with news, special offers, events and general information regarding the Project or the Service.
<br><br><b>To manage Your requests:</b> To attend and manage Your requests to Us.
<br><br><b>For business transfers:</b> We may use and process Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.
<br><br><b>For other purposes:</b> We may use and process Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our services, marketing and your experience.
</p>
<br>
<p style="text-align:justify; font-size: 16px; font-family: Segeo UI, Helvetica;">We may share Your personal information in the following situations:</p>
<br>
<p style="text-align:justify;margin: 0in 0in 0in 0.5in; ">
<b> With Service Providers</b>: We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.
<br><br>  	<b>For business transfers:</b> We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.
<br><br> 	<b>With Affiliates:</b> We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.
<br><br>  	<b>With business partners:</b> We may share Your information with Our business partners to offer You certain services or promotions.
<br><br>  	<b>With other users:</b> when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.
<br><br>  	<b>With competent authorities</b> to whom the Company is obligated or required to disclose according to legal regulations or requirement of the governmental authorities.
<br><br>   <b>With Your consent:</b> We may disclose Your personal information for any other purpose with Your consent.
</p>
<br>
<h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 17px; font-family: Segeo UI, Helvetica; color: black;">
Retention of Your Personal Data
</h3>
The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
<br><br>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.
<h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 17px; font-family: Segeo UI, Helvetica; color: black;">
Transfer of Your Personal Data
</h3>
Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country, or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.
<br><br>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.
<br><br>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.
<h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 17px; font-family: Segeo UI, Helvetica; color: black;">
Delete Your Personal Data
</h3>
You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You.
<br>
Our Service may give You the ability to delete certain information about You from within the Service.
<br>You may also contact Us to request access to, correct, or delete any personal information that You have provided to Us.
<br>Please note, however, that We may need to retain certain information when we have a legal obligation or lawful basis to do so.
<br><br>
<h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 17px; font-family: Segeo UI, Helvetica; color: black;">
Disclosure of Your Personal Data
</h3>
<h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 17px; font-family: Segeo UI, Helvetica; color: black;">
Business Transactions
</h3>
If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.
<h2 style="text-align:justify; font-weight:800;margin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 17px; font-family: Segeo UI, Helvetica; color: black;">
Law enforcement
</h2>
Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).
<h2 style="text-align:justify; font-weight:800;margin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 17px; font-family: Segeo UI, Helvetica; color: black;">
Other legal requirements
</h2>
The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:
<p style="text-align:justify;line-height: 24px">
⦁	Comply with a legal obligation
<br>
	Protect and defend the rights or property of the Company
<br>
⦁	Prevent or investigate possible wrongdoing in connection with the Service
<br>
⦁	Protect the personal safety of Users of the Service or the public
<br>
⦁	Protect against legal liability
</p>
<br>
<h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 17px; font-family: Segeo UI, Helvetica; color: black;">
Security of Your Personal Data
</h3>
The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.
<br><br><h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 17px; font-family: Segeo UI, Helvetica; color: black;">
Your Rights and Obligations regarding Your Personal Data
</h3>
You have the rights and obligations in respect of Your Personal Data in accordance with the applicable laws and regulations of Vietnam.
<h2 style="text-align:justify; font-weight:800;margin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 17px; font-family: Segeo UI, Helvetica; color: black;">
Children's Privacy
</h2>
Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.
<br>
<br>
If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.
<h2 style="text-align:justify; font-weight:800;margin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 17px; font-family: Segeo UI, Helvetica; color: black;">
Links to Other Websites
</h2>
Our Service may contain links to other websites that are not operated by Us. If You click on a third-party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.
<br>We have no control over and assume no responsibility for the content, privacy policies or practices of any third-party sites or services.
<br><br><h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 17px; font-family: Segeo UI, Helvetica; color: black;">
Changes to this Privacy Policy
</h3>
<p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.
<br><br>We will let You know via a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.
<br><br>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
<br><br>We may update our Privacy Policy from time to time. Your continued access to the Service and/or maintenance of relationships, interactions, and exchanges with the Company after we have posted our updated Privacy Policy on Our Service constitutes your acceptance of the updated Privacy Policy.
</p><br><h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 17px; font-family: Segeo UI, Helvetica; color: black;">
Contact Us
</h3>
<p style="text-align:justify;line-height: 24px">
If you have any questions about this Privacy Policy, You can contact us:

<p style="text-align:justify;margin: 0in 0in 0in 0.5in; line-height:  24px; font-size: 16px; font-family: Segeo UI, Helvetica;">
By email: inquiry@taiseidh.vn
<br>By phone number: +84888247289
</p></p>
</p>
 `
    },
    {
        id: "vi",
        content: `    <p class="MsoNormal" style="text-align:justify; line-height: 24px; font-size: 16px; font-family: Segeo UI, Helvetica;">
       <p class="MsoNormal" style="text-align:justify;margin: 0in 0in 13px; line-height: 24px; font-size: 18px; font-family: Barlow, Helvetica;"><b>Chính sách bảo mật</b></p>
       <p class="MsoNormal" style="text-align:justify;margin: 0in 0in 13px; line-height: 24px; font-size: 16px; font-family: Segeo UI, Helvetica;">Cập nhật lần cuối: ngày 21 tháng 9 năm 2023</p>
       <p class="MsoNormal" style="text-align:justify;margin: 0in 0in 13px; font-size: 16px; font-family: Segeo UI, Helvetica;">Chính sách Quyền riêng tư này mô tả các chính sách và quy trình của Chúng tôi về việc thu thập, sử dụng, xử lý và tiết lộ thông tin của Bạn khi Bạn sử dụng Dịch vụ, và cho Bạn biết về quyền riêng tư của Bạn cũng như việc luật pháp bảo vệ Bạn.</p>
       <p class="MsoNormal" style="text-align:justify;margin: 0in 0in 13px; line-height: 24px; font-size: 16px; font-family: Segeo UI, Helvetica;">Chúng tôi sử dụng dữ liệu Cá nhân của bạn để cung cấp và cải thiện Dịch vụ. Bằng cách sử dụng Dịch vụ, Bạn đồng ý với việc thu thập, sử dụng, xử lý và tiết lộ thông tin của Bạn theo Chính sách Quyền riêng tư này.</p>
       <h2  style="text-align:justify; font-weight:800;margin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 18px; font-family: Barlow, Helvetica; color: black;">Giải thích và định nghĩa</h2>
       <p class="MsoNormal" style="text-align:justify;margin: 0in 0in 13px; line-height: 24px; font-size: 18px; font-family: Segeo UI, Helvetica;">
       <h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 18px; font-family: Barlow, Helvetica; color: black;">Diễn dịch
</h3>Những từ có chữ cái đầu được viết hoa có nghĩa được xác định theo các điều kiện sau. Các định nghĩa sau đây sẽ có cùng ý nghĩa bất kể chúng xuất hiện ở số ít hay số nhiều.</p>
       <h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 18px; font-family: Barlow, Helvetica; color: black;">Các định nghĩa</h3>
       <p class="MsoNormal" style="text-align:justify;margin: 0in 0in 13px; line-height: 24px; font-size: 16px; font-family: Segeo UI, Helvetica;">Vì mục đích của Chính sách Quyền riêng tư này:
</p>
       <ul >
       <p style="text-align:justify; margin: 0in 0in 0in 0.5in; font-size: 16px; font-family: Segeo UI, Helvetica;"">
<span style="font-family:Barlow, Helvetica; font-size:17px"><b>Công ty liên kết </b></span> có nghĩa là một thực thể kiểm soát, bị kiểm soát hoặc chịu sự kiểm soát chung của một bên, trong đó "kiểm soát" có nghĩa là quyền sở hữu từ 50% cổ phần trở lên, lãi vốn cổ phần hoặc các chứng khoán khác có quyền bỏ phiếu bầu giám đốc hoặc cơ quan quản lý khác .</li>
<br><br><span style="font-family:Barlow, Helvetica; font-size:17px"><b>Công ty </b></span> (được gọi là "Công ty", hoặc "Chúng tôi" trong Chính sách này ) đề cập đến Công ty TNHH Phát triển Taisei Hà Nội, 289 Khuất Duy Tiên, Trung Hòa, Cầu Giấy, Hà Nội.</li>
<br><br><span style="font-family:Barlow, Helvetica; font-size:17px"><b>Cookie </b></span> là các tệp nhỏ được một Website đặt trên máy tính, thiết bị di động hoặc bất kỳ thiết bị nào khác của Bạn, chứa thông tin chi tiết về lịch sử duyệt web của Bạn trên Website đó.</li>
<br><br><span style="font-family:Barlow, Helvetica; font-size:17px"><b>Quốc gia:</b></span> Việt Nam.</li>
<br><br><span style="font-family:Barlow, Helvetica; font-size:17px"><b>Thiết bị </b></span> có nghĩa là bất kỳ thiết bị nào có thể truy cập Dịch vụ như máy tính, điện thoại di động hoặc máy tính bảng kỹ thuật số.</li>
<br><br><span style="font-family:Barlow, Helvetica; font-size:17px"><b>Dữ liệu Cá nhân </b></span> nghĩa là thông tin ở dạng ký hiệu, chữ viết, chữ số, hình ảnh hoặc âm thanh hoặc ở dạng tương tự trong môi trường điện tử được liên kết với một người cụ thể hoặc giúp nhận dạng một người cụ thể.</li>
<br><br><span style="font-family:Barlow, Helvetica; font-size:17px"><b>Dự án</b></span> là Taisei Square Hà Nội, tòa nhà văn phòng tọa lạc tại số 289 đường Khuất Duy Tiên , phường Trung Hòa, quận Cầu Giấy, Hà Nội.</li>
<br><br>
<span style="font-family:Barlow, Helvetica; font-size:17px"><b>Dịch vụ </b></span>  nghĩa là Website.</li>
<br><br><span style="font-family:Barlow, Helvetica; font-size:17px"><b>Nhà cung cấp dịch vụ </b></span>  có nghĩa là bất kỳ cá nhân hoặc pháp nhân nào xử lý dữ liệu thay mặt cho Công ty. Nó đề cập đến các công ty hoặc cá nhân bên thứ ba được Công ty tuyển dụng để hỗ trợ Dịch vụ, thay mặt Công ty cung cấp Dịch vụ, thực hiện các dịch vụ liên quan đến Dịch vụ hoặc hỗ trợ Công ty phân tích cách sử dụng Dịch vụ.</li>
<br><br><span style="font-family:Barlow, Helvetica; font-size:17px"><b>Dữ liệu sử dụng</b></span>  đề cập đến dữ liệu được thu thập tự động, được tạo bằng Dịch vụ hoặc từ chính cơ sở hạ tầng Dịch vụ (ví dụ: thời lượng truy cập trang).
</li>
<br><br><span style="font-family:Barlow, Helvetica; font-size:17px"><b>Website </b></span>  đề cập đến Taisei Square Hà Nội, có thể truy cập từ <a href="https://www.taiseisquarehanoi.com">https://www.taiseisquarehanoi.com</a></li>
<br><br><span style="font-family:Barlow, Helvetica; font-size:17px"><b>Bạn</b></span> có nghĩa là cá nhân truy cập hoặc sử dụng Dịch vụ, hoặc công ty hoặc pháp nhân khác thay mặt cho cá nhân đó đang truy cập hoặc sử dụng Dịch vụ, nếu có.</li>
     </p>
           </ul>
       <h2 style="text-align:justify; font-weight:800;margin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 18px; font-family: Barlow, Helvetica; color: black;">Thu thập, sử dụng và xử lý dữ liệu cá nhân của bạn</h2>
       <h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 18px; font-family: Barlow, Helvetica; color: black;">
Các loại dữ liệu được thu thập
</h3>
       <h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 18px; font-family: Barlow, Helvetica; color: black;">Dữ liệu cá nhân
</h3>
       
       <p class="MsoNormal" style="text-align:justify;margin: 0in 0in 13px; line-height: 24px; font-size: 16px; font-family: Segeo UI, Helvetica;">Trong khi sử dụng Dịch vụ của chúng tôi, Chúng tôi có thể yêu cầu Bạn cung cấp cho chúng tôi thông tin nhận dạng cá nhân nhất định, có thể được sử dụng để liên hệ hoặc nhận dạng Bạn. Thông tin nhận dạng cá nhân có thể bao gồm nhưng không giới hạn ở:</p>
       <p style="text-align:justify;margin: 0in 0in 0in 0.5in; line-height: 24px; font-size: 16px; font-family: Segeo UI, Helvetica;">
           Địa chỉ email
           <br>Tên và họ
           <br>Số điện thoại
           <br>Địa chỉ, Quận, Tỉnh, Thành phố
           <br>
Dữ liệu sử dụng

       </p>
       <h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 18px; font-family: Barlow, Helvetica; color: black;">Dữ liệu sử dụng</h3>
       <p style="text-align:justify;margin: 0in 0in 0in 0.5in;font-size: 16px; font-family: Segeo UI, Helvetica;">Dữ liệu sử dụng được thu thập tự động khi sử dụng Dịch vụ. <br>
<span>Dữ liệu sử dụng có thể bao gồm các thông tin như địa chỉ Giao thức Internet của Thiết bị của bạn (ví dụ: địa chỉ IP), loại trình duyệt, phiên bản trình duyệt, các trang Dịch vụ của chúng tôi mà Bạn truy cập, ngày và giờ Bạn truy cập, thời gian dành cho các trang đó, thiết bị định danh duy nhất và các dữ liệu chẩn đoán khác.

</span><br><br>Khi Bạn truy cập Dịch vụ bằng hoặc thông qua thiết bị di động, Chúng tôi có thể tự động thu thập một số thông tin nhất định, bao gồm nhưng không giới hạn ở loại thiết bị di động Bạn sử dụng, ID duy nhất của thiết bị di động của Bạn, địa chỉ IP của thiết bị di động của Bạn, hệ điều hành, loại trình duyệt Internet di động Bạn sử dụng, thiết bị định danh duy nhất và các dữ liệu chẩn đoán khác.
<span><br><br>Chúng tôi cũng có thể thu thập thông tin mà trình duyệt của Bạn gửi bất cứ khi nào Bạn truy cập Dịch vụ của chúng tôi hoặc khi Bạn truy cập Dịch vụ bằng hoặc thông qua thiết bị di động.
</span>
</p>

</p>
<h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 18px; font-family: Barlow, Helvetica; color: black;">
Công nghệ theo dõi và cookie
</h3>
<p style="text-align:justify; font-size: 16px; font-family: Segeo UI, Helvetica;">
Chúng tôi sử dụng Cookie và các công nghệ theo dõi tương tự để theo dõi hoạt động trên Dịch vụ của chúng tôi và lưu trữ một số thông tin nhất định. Các công nghệ theo dõi được sử dụng là đèn hiệu, thẻ và tập lệnh để thu thập và theo dõi thông tin cũng như cải thiện và phân tích Dịch vụ của chúng tôi. Các công nghệ chúng tôi sử dụng có thể bao gồm:

<p style="text-align:justify;margin: 0in 0in 0in 0.5in;  font-size: 16px; font-family: Segeo UI, Helvetica;"><br> <bold>Cookie hoặc Cookie trình duyệt.</bold> Cookie là một tệp nhỏ được đặt trên Thiết bị của bạn. Bạn có thể hướng dẫn trình duyệt của mình từ chối tất cả Cookie hoặc cho biết khi nào Cookie được gửi. Tuy nhiên, nếu Bạn không chấp nhận Cookie, Bạn có thể không sử dụng được một số phần trong Dịch vụ của chúng tôi. Trừ khi bạn đã điều chỉnh cài đặt trình duyệt của mình để từ chối Cookie, Dịch vụ của chúng tôi có thể sử dụng Cookie.<br>
<br>  <bold>Web beacon.</bold> Một số phần nhất định trong Dịch vụ của chúng tôi và email của chúng tôi có thể chứa các tệp nhỏ được gọi là web beacon (còn được gọi là ảnh gif trong suốt, thẻ pixel và ảnh gif pixel đơn), cho phép Công ty đếm số người dùng đã truy cập trang đó hoặc mở email, và xem các số liệu thống kê Website có liên quan khác (ví dụ: ghi lại mức độ phổ biến của một mục nhất định hay xác minh tính toàn vẹn của hệ thống và máy chủ).
</p></p>
<br>
Cookie có thể là Cookie "Liên tục" hoặc "Phiên". Cookie liên tục vẫn còn trên máy tính cá nhân hoặc thiết bị di động của Bạn khi Bạn ngắt kết nối, trong khi Cookie phiên sẽ bị xóa ngay khi Bạn đóng trình duyệt web của mình.
<br>
<br>
Chúng tôi sử dụng cả Cookie phiên và Cookie liên tục cho các mục đích được nêu dưới đây:
<h3 style="text-align:justify; font-weight:800;ckmargin: 0in 0in 0in 0.5in; line-height: 24px; break-after: avoid; font-size: 18px; font-family: Barlow, Helvetica; color: black;">Cookie cần thiết / thiết yếu
</h3>
<p style="text-align:justify;margin: 0in 0in 0in 0.5in; line-height: 24px; font-size: 16px; font-family: Segeo UI, Helvetica;">
Loại: Cookie phiên

<br>
Quản lý bởi: Chúng tôi
<br>
Mục đích: Những Cookie này rất cần thiết để cung cấp cho Bạn các dịch vụ có sẵn thông qua Website và cho phép Bạn sử dụng một số tính năng của nó. Chúng giúp xác thực người dùng và ngăn chặn việc sử dụng tài khoản người dùng một cách gian lận. Nếu không có những Cookie này, các dịch vụ mà Bạn yêu cầu sẽ không thể được cung cấp và Chúng tôi chỉ sử dụng những Cookie này để cung cấp cho Bạn những dịch vụ đó.
</p>
<h3 style="text-align:justify; font-weight:800;ckmargin: 0in 0in 0in 0.5in; line-height: 24px; break-after: avoid; font-size: 18px; font-family: Barlow, Helvetica; color: black;">Chính sách Cookies / Thông báo Chấp nhận Cookies

</h3>
<p style="text-align:justify;margin: 0in 0in 0in 0.5in; line-height: 24px; font-size: 16px; font-family: Segeo UI, Helvetica;">
Loại: Cookie liên tục

<br>
Quản lý bởi: Chúng tôi
<br>
Mục đích: Những Cookie này xác định xem người dùng có chấp nhận việc sử dụng cookie trên Website hay không.
</p>

<h3 style="text-align:justify; font-weight:800;ckmargin: 0in 0in 0in 0.5in; line-height: 24px; break-after: avoid; font-size: 18px; font-family: Barlow, Helvetica; color: black;">Cookie chức năng
</h3>
<p style="text-align:justify;margin: 0in 0in 0in 0.5in; line-height: 24px; font-size: 16px; font-family: Segeo UI, Helvetica;">
Loại: Cookie liên tục
<br>
Quản lý bởi: Chúng tôi
</p>
<h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 18px; font-family: Barlow, Helvetica; color: black;">
Mục đích
</h3>
<p style="text-align:justify; line-height: 24px; font-size: 16px; font-family: Segeo UI, Helvetica;">Những Cookie này cho phép chúng tôi ghi nhớ các lựa chọn Bạn thực hiện khi Bạn sử dụng Website, chẳng hạn như ghi nhớ chi tiết đăng nhập hoặc tùy chọn ngôn ngữ của bạn. Mục đích của những Cookie này là mang đến cho Bạn trải nghiệm mang tính cá nhân hơn và tránh việc Bạn phải nhập lại các tùy chọn của mình mỗi khi Bạn sử dụng Website.</p>
<h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 18px; font-family: Barlow, Helvetica; color: black;">
Sử dụng và xử lý dữ liệu cá nhân của bạn
</h3>
Công ty có thể sử dụng và xử lý Dữ liệu Cá nhân cho các mục đích sau:
<br><br>
<p style="text-align:justify;margin: 0in 0in 0in 0.5in;  font-size: 16px; ">
<span style="font-family:  Barlow, Helvetica"><b>Để cung cấp và duy trì Dịch vụ của chúng tôi</b></span>, bao gồm cả việc giám sát việc sử dụng Dịch vụ của chúng tôi. <br><br>
<span style="font-family:  Barlow, Helvetica"><b>Để liên hệ với Bạn:</b></span> Để liên hệ với Bạn qua email, điện thoại, SMS hoặc các hình thức liên lạc điện tử tương đương khác về các cập nhật hoặc thông tin liên quan đến Dự án hoặc Dịch vụ, bao gồm cả các cập nhật bảo mật, khi cần thiết hoặc hợp lý cho việc triển khai chúng.
<span style="font-family:  Barlow, Helvetica"><br><br><b>Để cung cấp cho Bạn </b></span> tin tức, ưu đãi đặc biệt, sự kiện và thông tin chung về Dự án hoặc Dịch vụ.
<span style="font-family:  Barlow, Helvetica"><br><br><b>Để quản lý các yêu cầu của Bạn: </b></span> Để nhận và quản lý các yêu cầu của Bạn đối với Chúng tôi.
<span style="font-family:  Barlow, Helvetica"><br><br><b>Đối với chuyển nhượng kinh doanh:</b></span> Chúng tôi có thể sử dụng và xử lý thông tin của Bạn để đánh giá hoặc tiến hành sáp nhập, thoái vốn, tái cơ cấu, tổ chức lại, giải thể hoặc bán hoặc chuyển nhượng một phần hoặc toàn bộ tài sản của Chúng tôi, cho dù là hoạt động liên tục hay là một phần của phá sản, thanh lý hoặc thủ tục tương tự, trong đó Dữ liệu cá nhân do Chúng tôi lưu giữ về người dùng Dịch vụ thuộc về tài sản được chuyển giao.
<span style="font-family:  Barlow, Helvetica"><br><br><b>Vì các mục đích khác: </b></span> Chúng tôi có thể sử dụng và xử lý thông tin của Bạn cho các mục đích khác, chẳng hạn như phân tích dữ liệu, xác định xu hướng sử dụng, xác định tính hiệu quả của các chiến dịch quảng cáo của chúng tôi cũng như để đánh giá và cải thiện dịch vụ, hoạt động tiếp thị và trải nghiệm của bạn.
</p>
<br>
<p>Chúng tôi có thể chia sẻ thông tin cá nhân của bạn trong các trường hợp sau:</p>
<br>
<p  style="text-align:justify;line-height: 24px; font-size:16px">
⦁<span style="font-family:  Barlow, Helvetica"><b> Với Nhà cung cấp dịch vụ:</b></span> Chúng tôi có thể chia sẻ thông tin cá nhân của Bạn với Nhà cung cấp dịch vụ để theo dõi và phân tích việc sử dụng Dịch vụ của chúng tôi, để liên hệ với Bạn.
<br>⦁	<span style="font-family:  Barlow, Helvetica"><b>
Đối với chuyển nhượng kinh doanh: </b></span> Chúng tôi có thể chia sẻ hoặc chuyển giao thông tin cá nhân của Bạn liên quan đến hoặc trong quá trình đàm phán về bất kỳ hoạt động sáp nhập, bán tài sản Công ty, tài trợ hoặc mua lại toàn bộ hoặc một phần hoạt động kinh doanh của Chúng tôi cho một công ty khác.
<br>⦁	<span style="font-family:  Barlow, Helvetica"><b>Với các chi nhánh:</b></span> Chúng tôi có thể chia sẻ thông tin của bạn với các chi nhánh của Chúng tôi, trong trường hợp đó, chúng tôi sẽ yêu cầu các chi nhánh đó tôn trọng Chính sách Quyền riêng tư này. Các chi nhánh bao gồm công ty mẹ của Chúng tôi và bất kỳ công ty con, đối tác liên doanh hoặc công ty nào khác mà Chúng tôi kiểm soát hoặc chịu sự kiểm soát chung.
<br>⦁	<span style="font-family:  Barlow, Helvetica"><b>Với các đối tác kinh doanh:</b></span> Chúng tôi có thể chia sẻ thông tin của Bạn với các đối tác kinh doanh của Chúng tôi để cung cấp cho Bạn một số dịch vụ hoặc khuyến mãi nhất định.
<br>⦁	<span style="font-family:  Barlow, Helvetica"><b>Với những người dùng khác:</b></span> khi Bạn chia sẻ thông tin cá nhân hoặc tương tác ở khu vực công cộng với những người dùng khác, thông tin đó có thể được tất cả người dùng xem và có thể được phân phối công khai ra bên ngoài.
<br>⦁	<span style="font-family:  Barlow, Helvetica"><b>Với cơ quan có thẩm quyền</b></span> mà Công ty có nghĩa vụ khai báo khi được yêu cầu theo quy định của pháp luật hoặc cơ quan chính phủ.

<br>⦁	<span style="font-family:  Barlow, Helvetica"><b>Với sự đồng ý của Bạn:</b></span> Chúng tôi có thể tiết lộ thông tin cá nhân của Bạn cho bất kỳ mục đích nào khác với sự đồng ý của Bạn.
</p>
<br>
<h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 18px; font-family: Barlow, Helvetica; color: black;">
Lưu giữ dữ liệu cá nhân của bạn</h3>
Công ty sẽ chỉ lưu giữ Dữ liệu cá nhân của bạn trong khoảng thời gian cần thiết cho các mục đích được nêu trong Chính sách Quyền riêng tư này. Chúng tôi sẽ lưu giữ và sử dụng Dữ liệu cá nhân của bạn trong phạm vi cần thiết để tuân thủ các nghĩa vụ pháp lý của chúng tôi (ví dụ: nếu chúng tôi được yêu cầu lưu giữ dữ liệu của bạn để tuân thủ luật hiện hành), giải quyết tranh chấp và thực thi các thỏa thuận và chính sách pháp lý của chúng tôi.
<br><br>Công ty cũng sẽ lưu giữ Dữ liệu sử dụng cho mục đích phân tích nội bộ. Dữ liệu sử dụng thường được lưu giữ trong khoảng thời gian ngắn hơn , trừ khi dữ liệu này được sử dụng để tăng cường bảo mật hoặc cải thiện chức năng của Dịch vụ hoặc Chúng tôi có nghĩa vụ pháp lý phải lưu giữ dữ liệu này trong khoảng thời gian dài hơn.
<h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 18px; font-family: Barlow, Helvetica; color: black;">
Chuyển dữ liệu cá nhân của bạn</h3>

Thông tin của bạn, bao gồm Dữ liệu cá nhân, được xử lý tại văn phòng điều hành của Công ty và ở bất kỳ nơi nào khác có trụ sở của các bên liên quan đến việc xử lý. Điều đó có nghĩa là thông tin này có thể được chuyển đến — và được duy trì trên — các máy tính nằm ngoài tỉnh, quốc gia của Bạn hoặc khu vực pháp lý khác của chính phủ, nơi luật bảo vệ dữ liệu có thể khác với luật bảo vệ dữ liệu của Bạn.
<br><br><p>Sự đồng ý của bạn với Chính sách Quyền riêng tư này kèm theo việc Bạn gửi thông tin đó thể hiện sự đồng ý của Bạn đối với việc chuyển giao đó.</p>
<br><br><p>Công ty sẽ thực hiện tất cả các bước cần thiết một cách hợp lý để đảm bảo rằng dữ liệu của Bạn được xử lý an toàn và tuân thủ Chính sách Quyền riêng tư này và việc chuyển Dữ liệu cá nhân của bạn sẽ không diễn ra cho một tổ chức hoặc quốc gia trừ khi có các biện pháp kiểm soát thích hợp, bao gồm cả bảo mật của Dữ liệu của bạn và thông tin cá nhân khác.</p>
<h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 18px; font-family: Barlow, Helvetica; color: black;">
Xóa dữ liệu cá nhân của bạn
</h3>
Bạn có quyền xóa hoặc yêu cầu Chúng tôi hỗ trợ xóa Dữ liệu Cá nhân mà Chúng tôi đã thu thập về Bạn.

<br>Dịch vụ của chúng tôi có thể cung cấp cho Bạn khả năng xóa một số thông tin nhất định về Bạn khỏi Dịch vụ.

<br>Bạn cũng có thể liên hệ với Chúng tôi để yêu cầu quyền truy cập, chỉnh sửa hoặc xóa bất kỳ thông tin cá nhân nào mà Bạn đã cung cấp cho Chúng tôi.

<br>Tuy nhiên, xin lưu ý rằng Chúng tôi có thể cần lưu giữ một số thông tin nhất định khi chúng tôi có nghĩa vụ pháp lý hoặc cơ sở hợp pháp để làm như vậy.

<br><br><h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 18px; font-family: Barlow, Helvetica; color: black;">
Tiết lộ dữ liệu cá nhân của bạn
</h3>
<h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 18px; font-family: Barlow, Helvetica; color: black;">
Giao dịch kinh doanh
</h3>
Nếu Công ty tham gia vào việc sáp nhập, mua lại hoặc bán tài sản, Dữ liệu cá nhân của bạn có thể được chuyển giao. Chúng tôi sẽ cung cấp thông báo trước khi Dữ liệu cá nhân của bạn được chuyển giao và tuân theo Chính sách Quyền riêng tư khác.
<h2 style="text-align:justify; font-weight:800;margin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 18px; font-family: Barlow, Helvetica; color: black;">
Thực thi pháp luật
</h2>
Trong một số trường hợp nhất định, Công ty có thể được yêu cầu tiết lộ Dữ liệu cá nhân của theo pháp luật hoặc để đáp ứng yêu cầu hợp lệ của cơ quan công quyền (ví dụ: tòa án hoặc cơ quan chính phủ).
<h2 style="text-align:justify; font-weight:800;margin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 18px; font-family: Barlow, Helvetica; color: black;">
Các yêu cầu pháp lý khác
</h2>
Công ty có thể tiết lộ Dữ liệu cá nhân của bạn với niềm tin rằng hành động đó là cần thiết để:
<p style="text-align:justify;line-height: 24px">
⦁	Tuân thủ nghĩa vụ pháp lý
<br>
⦁	Bảo vệ và bảo vệ các quyền hoặc tài sản của Công ty
<br>
⦁	Ngăn chặn hoặc điều tra các hành vi sai trái có thể xảy ra liên quan đến Dịch vụ
<br>
⦁		Bảo vệ sự an toàn cá nhân của Người dùng Dịch vụ hoặc của công chúng

<br>
⦁	Bảo vệ khỏi trách nhiệm pháp lý
</p>
<br><h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 18px; font-family: Barlow, Helvetica; color: black;">
Bảo mật dữ liệu cá nhân của bạn
</h3>
Việc bảo mật Dữ liệu Cá nhân của Bạn rất quan trọng đối với Chúng tôi, nhưng hãy nhớ rằng không có phương thức truyền qua Internet hoặc phương pháp lưu trữ điện tử nào an toàn 100%. Mặc dù Chúng tôi cố gắng sử dụng các phương tiện được chấp nhận về mặt thương mại để bảo vệ Dữ liệu Cá nhân của Bạn nhưng Chúng tôi không thể đảm bảo tính bảo mật tuyệt đối của dữ liệu đó.
<br>
<br><h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 18px; font-family: Barlow, Helvetica; color: black;">
Quyền và nghĩa vụ của bạn đối với Dữ liệu Cá nhân của bạn
</h3>
Bạn có các quyền và nghĩa vụ đối với Dữ liệu Cá nhân của Bạn theo luật pháp và quy định hiện hành của Việt Nam.
<h2 style="text-align:justify; font-weight:800;margin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 18px; font-family: Barlow, Helvetica; color: black;">
Quyền riêng tư của trẻ em
</h2>
Dịch vụ của chúng tôi không đề cập đến bất kỳ ai dưới 13 tuổi. Chúng tôi không cố ý thu thập thông tin nhận dạng cá nhân từ bất kỳ ai dưới 13 tuổi. Nếu Bạn là cha mẹ hoặc người giám hộ và Bạn biết rằng Con Bạn đã cung cấp Dữ liệu Cá nhân cho Chúng tôi, vui lòng liên hệ chúng tôi. Nếu Chúng tôi biết rằng Chúng tôi đã thu thập Dữ liệu Cá nhân từ bất kỳ ai dưới 13 tuổi mà không có xác minh về sự đồng ý của phụ huynh, Chúng tôi sẽ thực hiện các bước để xóa thông tin đó khỏi máy chủ của Chúng tôi.
<br>
<br>
Nếu Chúng tôi cần dựa vào sự đồng ý làm cơ sở pháp lý để xử lý thông tin của Bạn và quốc gia của Bạn cần có sự đồng ý của cha mẹ, Chúng tôi có thể yêu cầu sự đồng ý của cha mẹ Bạn trước khi Chúng tôi thu thập và sử dụng thông tin đó.

<h2 style="text-align:justify; font-weight:800;margin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 18px; font-family: Barlow, Helvetica; color: black;">
Liên kết đến các Website khác
</h2>
Dịch vụ của chúng tôi có thể chứa các liên kết đến các Website khác không do Chúng tôi điều hành. Nếu Bạn nhấp vào liên kết của bên thứ ba, Bạn sẽ được dẫn đến Website của bên thứ ba đó. Chúng tôi thực sự khuyên Bạn nên xem lại Chính sách Quyền riêng tư của mọi Website Bạn truy cập.
<br><br>Chúng tôi không kiểm soát và không chịu trách nhiệm về nội dung, chính sách bảo mật hoặc thông lệ của bất kỳ Website hoặc dịch vụ của bên thứ ba nào.
<br><br><h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 18px; font-family: Barlow, Helvetica; color: black;">
Những thay đổi đối với Chính sách Quyền riêng tư này</h3>
<p>Đôi khi, chúng tôi có thể cập nhật Chính sách Quyền riêng tư của mình. Chúng tôi sẽ thông báo cho Bạn về bất kỳ thay đổi nào bằng cách đăng Chính sách Quyền riêng tư mới trên trang này.

<br><br>Chúng tôi sẽ cho Bạn biết thông qua thông báo nổi bật trên Dịch vụ của chúng tôi, trước khi thay đổi có hiệu lực và cập nhật ngày "Cập nhật lần cuối" ở đầu Chính sách Quyền riêng tư này.

<br><br>Bạn nên xem lại Chính sách Quyền riêng tư này định kỳ để biết bất kỳ thay đổi nào. Các thay đổi đối với Chính sách Quyền riêng tư này có hiệu lực khi chúng được đăng trên trang này.
<br><br>Việc bạn tiếp tục truy cập Dịch vụ và/hoặc duy trì các mối quan hệ, tương tác và trao đổi với Công ty sau khi chúng tôi đăng Chính sách Quyền riêng tư cập nhật trên Dịch vụ của chúng tôi đồng nghĩa với việc bạn chấp nhận Chính sách Quyền riêng tư được cập nhật.
</p><br><h3 style="text-align:justify; font-weight:800;ckmargin: 13px 0in 0in; line-height: 24px; break-after: avoid; font-size: 18px; font-family: Barlow, Helvetica; color: black;">
Liên hệ chúng tôi
</h3>
<p style="text-align:justify;line-height: 24px">
Nếu bạn có bất kỳ câu hỏi nào về Chính sách Quyền riêng tư này, Bạn có thể liên hệ với chúng tôi:

<p style="text-align:justify;margin: 0in 0in 0in 0.5in; line-height:  24px; font-size: 16px; font-family: Segeo UI, Helvetica;">
Qua email: inquiry@taiseidh.vn
<br>
Theo số điện thoại: +84888247289
</p></p>
</p>`
    }
];
