import "../../style/style.css";
import PropTypes from "prop-types";
import { ScrollNavbar, useWindowWidth } from "../../breakpoints";
import { useState, useEffect, useRef } from "react";
import { MenuWrapper, MenuContent, LanguageParagraph, LanguageWrapper, LanguageMenu, IconPhone, HotLine, MenuItems, MenuLineFull, ImgLineShort, SubMenu, MenuLink, SubMenuLink, HeaderWrapper, HeaderContent, LogoImage, MenuPhone, MenuButton, LogoContentMobile, LogoContent, HeaderMobile, MenuMobile, MenuItemsMobile, MenuTitleMobile, MenuTitleLink, MenuContentMobile, SubMenuContentMobile, SubMenuTitleMobile, SubMenuTitleLink, SubMenuContent, MenuLanguageMobile, MenuLanguageItemMobile, LanguageDisplay } from "../../style/Header";
import { Link, useLocation } from "react-router-dom";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../LanguageContext";
import { getMatterPort } from "../../api/virtualTour";

const MenuTitleDesktop = ({ title, link, children, left, isScroll, align = "center", width = "0px", imgwidth = "100%" }) => {
    const location = useLocation();
    const active = (location.pathname === "/" && link === "/overview") || location.pathname === link ? "true" : "false";
    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            const yOffset = section.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({ top: yOffset, behavior: "smooth" });
        } else {
            setTimeout(() => {
                const sectionFt = document.getElementById(sectionId);
                if (sectionFt) {
                    const yOffset = sectionFt.getBoundingClientRect().top;
                    window.scrollTo({ top: yOffset, behavior: "smooth" });
                }
            }, 100);
        }
    };

    const subMenuContent = title !== "Contact" ? <SubMenuContent isscroll={isScroll}>{children}</SubMenuContent> : null;

    const handleClick = () => {
        scrollToSection(link === "/virtualtour" ? "virtualtour" : "hero");
    };
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;
    return (
        <>
            <MenuContent scrolled={isScroll} left={left} width={width} align={align}>
                <MenuLink languagecode={currentLanguageCode} to={link} active={active} onClick={handleClick}>
                    {t(title)}
                </MenuLink>
                <ImgLineShort alt="Line" src="./img/line-2.png" width={imgwidth} isscroll={isScroll} />
                {subMenuContent}
            </MenuContent>
        </>
    );
};

const SubMenuTitleDesktop = ({ title, submenu, children, align = "center" }) => {
    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            const yOffset = section.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({ top: yOffset - 20, behavior: "smooth" });
        } else {
            window.scrollTo({ top: 0, behavior: "smooth" });
            setTimeout(() => {
                const sectionFt = document.getElementById(sectionId);
                if (sectionFt) {
                    const yOffset = sectionFt.getBoundingClientRect().top;
                    window.scrollTo({ top: yOffset - 20, behavior: "smooth" });
                }
            }, 1700);
        }
    };

    const { i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    return (
        <MenuItems textalign={align}>
            <SubMenu>
                <SubMenuLink languagecode={currentLanguageCode} to={`/${title}#${submenu}`} onClick={() => scrollToSection(submenu)}>
                    {children}
                </SubMenuLink>
            </SubMenu>
        </MenuItems>
    );
};

const MenuTitle = ({ title, link, initialShow, handleMobile, children }) => {
    const location = useLocation();
    const active = (location.pathname === "/" && link === "/overview") || location.pathname === link ? "true" : "false";
    const [isSubMenuVisible, setSubMenuVisible] = useState(initialShow);

    const toggleSubMenu = () => {
        setSubMenuVisible(!isSubMenuVisible);
    };

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            const yOffset = section.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({ top: yOffset, behavior: "smooth" });
        } else {
            setTimeout(() => {
                const sectionFt = document.getElementById(sectionId);
                if (sectionFt) {
                    const yOffset = sectionFt.getBoundingClientRect().top;
                    window.scrollTo({ top: yOffset, behavior: "smooth" });
                }
            }, 100);
        }
    };

    const handleClick = () => {
        scrollToSection(link === "/virtualtour" ? "virtualtour" : "hero");
    };

    const { i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    return (
        <MenuContentMobile>
            <MenuItemsMobile>
                <MenuTitleMobile
                    languagecode={currentLanguageCode}
                    onClick={() => {
                        handleClick();
                        handleMobile();
                    }}
                >
                    <MenuTitleLink to={link} active={active}>
                        {title}
                    </MenuTitleLink>
                </MenuTitleMobile>
                {link !== "/contact" && (
                    <MenuTitleMobile onClick={toggleSubMenu} languagecode={currentLanguageCode} fsize={"21px"} tablet={"27px"} align={"right"}>
                        {isSubMenuVisible ? "-" : "+"}
                    </MenuTitleMobile>
                )}
            </MenuItemsMobile>

            {isSubMenuVisible && children}
        </MenuContentMobile>
    );
};

const SubMenuTitle = ({ title, submenu, children }) => {
    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            const yOffset = section.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({ top: yOffset, behavior: "smooth" });
        } else {
            setTimeout(() => {
                const sectionFt = document.getElementById(sectionId);
                if (sectionFt) {
                    const yOffset = sectionFt.getBoundingClientRect().top;
                    window.scrollTo({ top: yOffset, behavior: "smooth" });
                }
            }, 1700);
        }
    };

    const { i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    return (
        <SubMenuTitleMobile languagecode={currentLanguageCode}>
            <SubMenuTitleLink to={`/${title}#${submenu}`} onClick={() => scrollToSection(submenu)}>
                {children}
            </SubMenuTitleLink>
        </SubMenuTitleMobile>
    );
};

function Header() {
    const screenWidth = useWindowWidth();
    let isScroll = ScrollNavbar(80);
    const [isScrolling, setIsScrolling] = useState("false");
    const [isHovered, setIsHovered] = useState("false");
    const [isMenuFullContentVisible, setMenuFullContentVisible] = useState(false);
    const headerMobileRef = useRef(null);
    const defaultLang = localStorage.getItem("selectedLang") ?? "en";
    const { t } = useTranslation();
    const { selectedLang, setSelectedLang } = useLanguage();

    const handleMenuHover = () => {
        setIsHovered("true");
        setMenuFullContentVisible(true);
    };

    const handleLanguageChange = (lang) => {
        localStorage.setItem("selectedLang", lang);
        i18n.changeLanguage(lang);
        setSelectedLang(lang);
        window.location.reload();
    };

    const handleMenuLeave = () => {
        setIsHovered("false");
        setMenuFullContentVisible(false);
    };

    const handleMenuMobile = () => {
        setMenuFullContentVisible(!isMenuFullContentVisible);
    };

    useEffect(() => {
        setIsScrolling(isScroll === true ? "true" : "false");
    }, [isScroll]);
    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem("selectedLang"));
        function handleClickOutside(event) {
            if (headerMobileRef.current && !headerMobileRef.current.contains(event.target)) {
                setMenuFullContentVisible(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    const [matterPortUrl, setMatterPortUrl] = useState("");
    useEffect(() => {
        getDataVirtualTour();
    }, []);
    const getDataVirtualTour = async () => {
        const matterUrl = await getMatterPort();
        if (matterUrl) {
            setMatterPortUrl(matterUrl);
        }
    };
    return (
        <>
            {screenWidth < 1280 ? (
                <>
                    <HeaderMobile ref={headerMobileRef} scrolled={isScrolling}>
                        <LogoContentMobile>
                            <div className="flex-logo">
                                <Link to="/">
                                    <LogoImage scrolled={isScrolling} src="./img/logo-white.png" />
                                </Link>
                            </div>
                            <div className="flex-menubutton">
                                <MenuButton src="./img/menu-button.png" onClick={handleMenuMobile} />
                            </div>
                        </LogoContentMobile>
                        {isMenuFullContentVisible && (
                            <MenuMobile>
                                <MenuTitle title={t("Overview")} link="/overview" initialShow={false} handleMobile={handleMenuMobile}>
                                    <SubMenuContentMobile onClick={handleMenuMobile}>
                                        <SubMenuTitle title="overview" submenu="overview">{`❯ ${t("SubOverview")}`}</SubMenuTitle>
                                        <SubMenuTitle title="overview" submenu="concept">{`❯ ${t("Concept")}`}</SubMenuTitle>
                                        <SubMenuTitle title="overview" submenu="location">{`❯ ${t("Location")}`}</SubMenuTitle>
                                    </SubMenuContentMobile>
                                </MenuTitle>
                                <MenuTitle title={t("The Building")} link="/thebuilding" initialShow={false} handleMobile={handleMenuMobile}>
                                    <SubMenuContentMobile onClick={handleMenuMobile}>
                                        <SubMenuTitle title="thebuilding" submenu="key-features">{`❯ ${t("Key Features")}`}</SubMenuTitle>
                                        <SubMenuTitle title="thebuilding" submenu="amenities">{`❯ ${t("Amenities")}`}</SubMenuTitle>
                                        <SubMenuTitle title="thebuilding" submenu="sustainability">{`❯ ${t("Sustainability")}`}</SubMenuTitle>
                                    </SubMenuContentMobile>
                                </MenuTitle>
                                <MenuTitle title={t("Leasing")} link="/leasing" initialShow={false} handleMobile={handleMenuMobile}>
                                    <SubMenuContentMobile onClick={handleMenuMobile}>
                                        <SubMenuTitle title="leasing" submenu="office">{`❯ ${t("Office")}`}</SubMenuTitle>
                                        <SubMenuTitle title="leasing" submenu="retail">{`❯ ${t("Retail")}`}</SubMenuTitle>
                                    </SubMenuContentMobile>
                                </MenuTitle>
                                <MenuTitle title={t("Virtual Tour")} link="/virtualtour" initialShow={false} handleMobile={handleMenuMobile}>
                                    <SubMenuContentMobile onClick={handleMenuMobile}>
                                        {matterPortUrl && <SubMenuTitle title="virtualtour" submenu="virtualtour">{`❯ ${t("360Tour")}`}</SubMenuTitle>}
                                        <SubMenuTitle title="virtualtour" submenu="gallery">{`❯ ${t("Photo Gallery")}`}</SubMenuTitle>
                                    </SubMenuContentMobile>
                                </MenuTitle>
                                <MenuTitle title={t("About Us")} link="/developer" initialShow={false} handleMobile={handleMenuMobile}>
                                    <SubMenuContentMobile onClick={handleMenuMobile}>
                                        <SubMenuTitle title="developer" submenu="developer">{`❯ ${t("SubDeveloper")}`}</SubMenuTitle>
                                        <SubMenuTitle title="developer" submenu="partners">{`❯ ${t("Partners")}`}</SubMenuTitle>
                                    </SubMenuContentMobile>
                                </MenuTitle>
                                <MenuTitle title={t("Media")} link="/media" initialShow={false} handleMobile={handleMenuMobile}>
                                    <SubMenuContentMobile onClick={handleMenuMobile}>
                                        <SubMenuTitle title="media" submenu="news">{`❯ ${t("News")}`}</SubMenuTitle>
                                        <SubMenuTitle title="media" submenu="press">{`❯ ${t("Press")}`}</SubMenuTitle>
                                    </SubMenuContentMobile>
                                </MenuTitle>
                                <MenuTitle title={t("Contact")} link="/contact" initialShow={false} handleMobile={handleMenuMobile}></MenuTitle>

                                <MenuLanguageMobile>
                                    <MenuLanguageItemMobile languagecode={selectedLang} selected={selectedLang === "en" || (!selectedLang && defaultLang === "en")} left="160px" onClick={() => handleLanguageChange("en")}>
                                        ENGLISH
                                    </MenuLanguageItemMobile>
                                    <MenuLanguageItemMobile selected={selectedLang === "jp"} left="205px" onClick={() => handleLanguageChange("jp")}>
                                        日本語
                                    </MenuLanguageItemMobile>
                                    <MenuLanguageItemMobile selected={selectedLang === "vi"} left="250px" onClick={() => handleLanguageChange("vi")}>
                                        TIẾNG VIỆT
                                    </MenuLanguageItemMobile>
                                </MenuLanguageMobile>
                            </MenuMobile>
                        )}
                    </HeaderMobile>
                </>
            ) : (
                <>
                    <HeaderWrapper scrolled={isScrolling}>
                        <HeaderContent>
                            <LogoContent>
                                <Link to="/">
                                    <LogoImage scrolled={isScrolling} src="./img/logo-white.png" />
                                </Link>
                            </LogoContent>

                            <MenuWrapper scrolled={isScrolling} hovered={isHovered} onMouseEnter={handleMenuHover} onMouseLeave={handleMenuLeave}>
                                <MenuLineFull scrolled={isScrolling}>{/* <ImgLineLong alt="Line" src="./img/line-1.png" /> */}</MenuLineFull>
                                <MenuTitleDesktop title="Overview" link="/overview" width="139px" isScroll={isScrolling} left="0px" imgwidth="100%">
                                    <SubMenuTitleDesktop title="overview" submenu="overview" align="left">
                                        {t("SubOverview")}
                                    </SubMenuTitleDesktop>
                                    <SubMenuTitleDesktop title="overview" submenu="concept" align="left">
                                        {t("Concept")}
                                    </SubMenuTitleDesktop>
                                    <SubMenuTitleDesktop title="overview" submenu="location" align="left">
                                        {t("Location")}
                                    </SubMenuTitleDesktop>
                                </MenuTitleDesktop>
                                <MenuTitleDesktop title="The Building" link="/thebuilding" left={selectedLang === "en" ? "135px" : selectedLang === "jp" ? "90px" : "118px"} isScroll={isScrolling} width={selectedLang === "en" ? "160px" : selectedLang === "jp" ? "170px" : "160px"} imgwidth="100%">
                                    <SubMenuTitleDesktop title="thebuilding" submenu="key-features">
                                        {t("Key Features")}
                                    </SubMenuTitleDesktop>
                                    <SubMenuTitleDesktop title="thebuilding" submenu="amenities">
                                        {t("Amenities")}
                                    </SubMenuTitleDesktop>
                                    <SubMenuTitleDesktop title="thebuilding" submenu="sustainability">
                                        {t("Sustainability")}
                                    </SubMenuTitleDesktop>
                                </MenuTitleDesktop>
                                <MenuTitleDesktop title="Leasing" link="/leasing" left={selectedLang === "en" ? "294px" : selectedLang === "jp" ? "250px" : "250px"} isScroll={isScrolling} width={selectedLang === "en" ? "133px" : selectedLang === "jp" ? "120px" : "133px"} imgwidth="100%">
                                    <SubMenuTitleDesktop title="leasing" submenu="office">
                                        {t("Office")}
                                    </SubMenuTitleDesktop>
                                    <SubMenuTitleDesktop title="leasing" submenu="retail">
                                        {t("Retail")}
                                    </SubMenuTitleDesktop>
                                </MenuTitleDesktop>
                                <MenuTitleDesktop title="Virtual Tour" link="/virtualtour" left={selectedLang === "en" ? "428px" : selectedLang === "jp" ? "390px" : "380px"} isScroll={isScrolling} width={selectedLang === "en" ? "148px" : selectedLang === "jp" ? "180px" : "148px"} imgwidth="100%">
                                    {matterPortUrl && (
                                        <SubMenuTitleDesktop title="virtualtour" submenu="virtualtour">
                                            {t("360Tour")}
                                        </SubMenuTitleDesktop>
                                    )}
                                    <SubMenuTitleDesktop title="virtualtour" submenu="gallery">
                                        {t("Photo Gallery")}
                                    </SubMenuTitleDesktop>
                                </MenuTitleDesktop>
                                <MenuTitleDesktop title="About Us" link="/developer" left={selectedLang === "en" ? "579px" : selectedLang === "jp" ? "590px" : "550px"} isScroll={isScrolling} width={selectedLang === "en" ? "132px" : selectedLang === "jp" ? "116px" : "132px"} imgwidth="100%">
                                    <SubMenuTitleDesktop title="developer" submenu="investor">
                                        {t("SubDeveloper")}
                                    </SubMenuTitleDesktop>
                                    <SubMenuTitleDesktop title="developer" submenu="partners">
                                        {t("Partners")}
                                    </SubMenuTitleDesktop>
                                </MenuTitleDesktop>
                                <MenuTitleDesktop title="Media" link="/media" left={selectedLang === "en" ? "713px" : selectedLang === "jp" ? "685px" : "713px"} isScroll={isScrolling} width={selectedLang === "en" ? "128px" : selectedLang === "jp" ? "185px" : "128px"} imgwidth="100%">
                                    <SubMenuTitleDesktop title="media" submenu="news">
                                        {t("News")}
                                    </SubMenuTitleDesktop>
                                    <SubMenuTitleDesktop title="media" submenu="press">
                                        {t("Press")}
                                    </SubMenuTitleDesktop>
                                </MenuTitleDesktop>
                                <MenuTitleDesktop title="Contact" link="/contact" left={selectedLang === "en" ? "842px" : selectedLang === "jp" ? "854px" : "842px"} isScroll={isScrolling} align="center" width={selectedLang === "en" ? "139px" : selectedLang === "jp" ? "80px" : "139px"} imgwidth="100%"></MenuTitleDesktop>
                            </MenuWrapper>
                            <LanguageMenu scrolled={isScrolling}>
                                <LanguageDisplay>
                                    <HotLine>
                                        <IconPhone />
                                        <MenuPhone>0888.247.289</MenuPhone>
                                    </HotLine>
                                    <LanguageWrapper selected={selectedLang === "en" || (!selectedLang && defaultLang === "en")} onClick={() => handleLanguageChange("en")}>
                                        <LanguageParagraph left="10px">EN</LanguageParagraph>
                                    </LanguageWrapper>
                                    <LanguageWrapper selected={selectedLang === "jp"} onClick={() => handleLanguageChange("jp")}>
                                        <LanguageParagraph left="14px">JP</LanguageParagraph>
                                    </LanguageWrapper>
                                    <LanguageWrapper selected={selectedLang === "vi"} onClick={() => handleLanguageChange("vi")}>
                                        <LanguageParagraph left="10px">VI</LanguageParagraph>
                                    </LanguageWrapper>
                                </LanguageDisplay>
                            </LanguageMenu>
                        </HeaderContent>
                        {/* <MenuFullContent scrolled={isScrolling} className={isMenuFullContentVisible ? "show" : ""} /> */}
                    </HeaderWrapper>
                </>
            )}
        </>
    );
}

MenuTitleDesktop.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.element),
    left: PropTypes.string.isRequired,
    isScroll: PropTypes.string.isRequired,
    align: PropTypes.string,
    width: PropTypes.string,
    imgwidth: PropTypes.string
};

SubMenuTitleDesktop.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.string,
    submenu: PropTypes.string.isRequired,
    align: PropTypes.string
};

MenuTitle.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    children: PropTypes.object,
    initialShow: PropTypes.bool.isRequired,
    handleMobile: PropTypes.func
};

SubMenuTitle.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.string.isRequired,
    submenu: PropTypes.string.isRequired
};

export default Header;
