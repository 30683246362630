import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { TextAnimation } from "../../../breakpoints";
import { animated } from "@react-spring/web";
import { useInView } from "react-intersection-observer";
import PropTypes from "prop-types";
import MoreDetailButtonComponent from "../../../components/ContactUsButton";
import { LeasingDisplay, LeasingSectionBackground, LeasingParagraphs, LeasingImage, LeasingWrapper, LeasingJustifyContent, LeasingDivElement } from "../../../style/LeasingScreen";
import { useTranslation } from "react-i18next";

const RetailSection = ({ onHeightChange }) => {
    const sectionRef = useRef(null);
    const [retailRef, inRetailRef] = useInView();
    const descriptionSpring = TextAnimation(inRetailRef);
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    useEffect(() => {
        const sectionHeight = sectionRef.current ? sectionRef.current.clientHeight : 0;
        onHeightChange((prevHeight) => prevHeight + sectionHeight);
    }, [onHeightChange]);

    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);

    return (
        <LeasingSectionBackground id="retail" ref={sectionRef}>
            <LeasingWrapper height="1750px" position="relative" width="1366px" className="retail-space" ref={retailRef}>
                <LeasingParagraphs languagecode={currentLanguageCode} font={"'Barlow', Helvetica"} fweight={"700"} fsize={"50px"} linehg={"47px"} padding={"82px 0px 0px 0px"}>
                    <animated.div style={descriptionSpring}>{t("RETAIL SPACE")}</animated.div>
                </LeasingParagraphs>
                <LeasingDisplay align={"center"} direction={"column"} padding={"60px 0"}>
                    <LeasingDivElement className="floorplan-1-retail" height={"610px"} width={"1202px"} border={"1px solid #707070"}>
                        <LeasingImage className="img-floorplan1" src="./img/floorplan-1-retail.png" height={"608px"} width={"1200px"} />
                    </LeasingDivElement>
                    <LeasingDivElement className="floorplan-2-retail" margin={"60px 0 0 0"} height={"610px"} width={"1202px"} border={"1px solid #707070"}>
                        <LeasingImage className="img-floorplan2" src="./img/floorplan-2-retail.png" height={"608px"} width={"1200px"} />
                    </LeasingDivElement>
                </LeasingDisplay>
                <LeasingJustifyContent>
                    <Link to="/contact">
                        <MoreDetailButtonComponent text={"MORE DETAILS"} width={"210px"} />
                    </Link>
                </LeasingJustifyContent>
            </LeasingWrapper>
        </LeasingSectionBackground>
    );
};

RetailSection.propTypes = {
    onHeightChange: PropTypes.func
};

export default RetailSection;
