import styled from "styled-components";
import { Link } from "react-router-dom";

export const TheBuildingHeroButtonSection = styled.div`
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 251px;
    @media (min-width: 1367px) and (min-width: 1281px) {
        margin-top: 300px;
    }

    @media (min-width: 360px) and (max-width: 1280px) {
        height: 90px;
        margin: 10px 0px;
        justify-content: center;
        flex-direction: column;
    }
`;

export const TheBuildingHeroButton = styled.div`
    color: #f4f4f4;
    font-family: "Barlow", Helvetica;
    border: 3px solid #f4f4f4;
    width: 150px;
    height: 40px;
    text-align: center;
    box-shadow: 0 1px 2px #353535;
    cursor: pointer;
    margin-right: 10px;
    margin-left: 10px;
    &:hover {
        background-color: #cbe7f2;
        color: #1b2e59;
    }

    @media (max-width: 480px) {
        height: 40px;
        margin: 0px 0px 5px 0px;
    }
`;

export const TheBuildingHeroButtonText = styled.div`
    font-size: 21px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 21px;
    text-shadow: 0px 1px 2px #353535;
    white-space: nowrap;
    margin-top: 5px;
`;

export const ContactUsButton = styled.div`
    border: 3px solid ${(props) => props.color ?? "#323b4a"};
    box-shadow: 0px 1px 2px #353535;
    height: 40px;
    width: ${(props) => props.width ?? "170px"};
    display: ${(props) => (props.hidden ? "none" : "flex")};
    justify-content: center;
    align-items: center;
    &:hover {
        cursor: pointer;
        background-color: #cbe7f2;
        border: none;
        text-shadow: none;
    }
`;

export const ContactUsText = styled.div`
    color: ${(props) => props.color ?? "#323b4a"};
    font-family: ${(props) => props.font ?? "'Barlow', Helvetica"};
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 21px;
    white-space: nowrap;
    padding-bottom: 1px;
    text-shadow: 0px 1px 2px #353535;
    ${ContactUsButton}:hover & {
        color: #1b2e59;
        text-shadow: none;
    }
`;

export const ExploreButton = styled.div`
    border: 3px solid ${(props) => props.color ?? "#323b4a"};
    box-shadow: 0px 1px 2px #353535;
    height: 40px;
    width: ${(props) => props.width ?? "170px"};
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.color ?? "#323b4a"};
    font-family: "Barlow", Helvetica;
    font-size: 21px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 21px;
    white-space: nowrap;
    padding-bottom: 1px;
    text-shadow: 0px 1px 2px #353535;
    &:hover {
        cursor: pointer;
        background-color: #cbe7f2;
        border: none;
        text-shadow: none;
        color: #1b2e59;
        text-shadow: none;
    }
`;

export const TextButton = styled.div`
    color: #f4f4f4;
    font-family: "Barlow", Helvetica;
    font-size: 21px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 21px;
    text-shadow: 0px 1px 2px #353535;
    padding-top: 6px;
    text-align: center;
    white-space: nowrap;
`;

export const ElementButton = styled.button`
    background-color: #ffffff;
    border: ${(props) => props.border ?? "3px solid"};
    border-color: #323b4a;
    border-bottom: ${(props) => props.bdbottom};
    box-shadow: ${(props) => props.shadow ?? "none"};
    height: ${(props) => props.height};
    position: ${(props) => props.position};
    top: 2px;
    width: ${(props) => props.width};
    &:hover {
        cursor: pointer;
        color: #2c53af;
        background-color: ${(props) => props.hoverbgcolor ?? " #cbe7f2"};
    }
`;

export const ButtonContent = styled.div`
    color: #323b4a;
    font-family: "Barlow", Helvetica;
    font-size: 21px;
    left: 32px;
    letter-spacing: 0;
    line-height: 21px;
    text-shadow: 0px 1px 2px #353535;
    top: 6px;
    white-space: nowrap;
    right: 20px;
`;

export const SpanWrapper = styled.span`
    font-family: "Barlow", Helvetica;
    font-weight: ${(props) => props.weight};
    text-align: ${(props) => props.align};
`;

export const ContactUsButtonForSustainability = styled.button`
    all: unset;
    border: 3px solid;
    border-color: #323b4a;
    box-shadow: 0px 2px 4px #353535;
    box-sizing: border-box;
    height: 40px;
    left: ${(props) => props.left};
    position: absolute;
    top: ${(props) => props.top};
    width: ${(props) => props.width};
`;

export const ContactUsTextForSustainability = styled(Link)`
    color: #323b4a;
    font-family: "Barlow", Helvetica;
    font-size: ${(props) => props.fsize};
    font-weight: 600;
    left: ${(props) => props.left};
    letter-spacing: 0;
    line-height: ${(props) => props.fsize};
    position: absolute;
    text-shadow: 0px 2px 4px #353535;
    top: ${(props) => props.top};
    white-space: nowrap;
`;

export const ButtonExplore = styled(Link)`
    border: 3px solid;
    border-color: #f4f4f4;
    box-shadow: 0px 2px 4px #353535;
    height: 40px;
    left: ${(props) => props.left};
    position: absolute;
    top: ${(props) => props.top};
    width: 150px;
`;

export const TextExplore = styled.div`
    color: #f4f4f4;
    font-family: "Barlow", Helvetica;
    font-size: 21px;
    font-weight: 600;
    left: 28px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    text-shadow: 0px 2px 4px #353535;
    top: 6px;
    white-space: nowrap;
`;

export const TextBold = styled.span`
    font-family: SegoeUI-Bold, Helvetica;
    font-weight: 700;
`;

export const TextWrapperExplore = styled.div`
    font-family: ${(props) => props.fontFamily};
    font-size: ${(props) => props.fontSize};
    color: ${(props) => props.color};
    text-shadow: 0 2px 4px #353535;
    font-family: ${(props) => props.fontFamily};
`;
