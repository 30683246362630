import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import MoreDetailButtonComponent from "../../../components/ContactUsButton";
import { HeroLanding, MediaDisplay, MediaHeroWrapper } from "../../../style/MediaScreen";

import { useTranslation } from "react-i18next";

const HeroSection = ({ onHeightChange }) => {
    const sectionRef = useRef(null);
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);

    useEffect(() => {
        const sectionHeight = sectionRef.current ? sectionRef.current.clientHeight : 0;
        onHeightChange(sectionHeight);
    }, [onHeightChange]);

    return (
        <HeroLanding id="hero" position="relative" bgurl="6-hero.png" ref={sectionRef}>
            <MediaDisplay direction={"column"} align={"center"}>
                <MediaHeroWrapper languagecode={currentLanguageCode} color={"#F4F4F4"} fsize={"67px"} linehg={"70px"} shadow={"0px 2px 4px #353535"} fweight={"600"} padding={"0 0 20px 0"}>
                    {t("NEWS & PRESS")}
                </MediaHeroWrapper>
                <Link to="/contact">
                    <MoreDetailButtonComponent color={"#F4F4F4"} text={"MORE DETAILS"} hidden={true} />
                </Link>
            </MediaDisplay>
        </HeroLanding>
    );
};

HeroSection.propTypes = {
    onHeightChange: PropTypes.func
};

export default HeroSection;
