{
    "A JAPANESE DEDICATION TO WORKPLACE AND WELLBEING OF THE PROFESSIONALS": "SỰ CHỈN CHU CỦA NGƯỜI NHẬT VỀ MỘT NƠI LÀM VIỆC VÌ SỨC KHỎE VÀ SỰ CHUYÊN NGHIỆP",
    "The first LEED certified Grade A office building in West Hanoi": "Tòa nhà văn phòng Hạng A đầu tiên đạt chứng chỉ LEED tại khu vực Tây Hà Nội",
    "OVERVIEW": "TỔNG QUAN DỰ ÁN",
    "OVERVIEW DESCRIPTION": "Taisei Square Hanoi sẽ đặt ra những chuẩn mực mới cho thị trường văn phòng ở khu vực Cầu Giấy nhộn nhịp phía Tây thủ đô. Được đầu tư 100% và phát triển toàn bộ bởi Taisei Corporation - Tập đoàn xây dựng uy tín hàng đầu Nhật Bản, tòa nhà văn phòng Hạng A chuẩn quốc tế này cam kết mang đến chất lượng tuyệt đối và dịch vụ chuyên nghiệp nhất cho khách hàng.",
    "18floors": "18 tầng <br /> văn phòng cho thuê",
    "Floorplate": "Diện tích mặt sàn 1.300 m²",
    "2floors": "2 tầng <br /> thương mại dịch vụ",
    "4basement": "4 tầng hầm <br />  để xe",
    "leedcertificate": "Chứng chỉ <br /> LEED",
    "PROJECT CONCEPT": "Ý TƯỞNG CỦA DỰ ÁN",
    "PROJECT CONCEPT DESCRIPTION": "Dự án được phát triển với mục tiêu đóng góp cho thị trường Hà Nội không gian làm việc chất lượng Nhật Bản, tiện nghi và có tính bền vững. Dự án là một sản phẩm hoàn hảo dưới sự phối hợp khăng khít của các công ty trực thuộc Tập đoàn Taisei từ khâu đầu tư, thiết kế, thi công và vận hành kinh doanh. Đội ngũ quản lý vận hành chuyên nghiệp kết hợp với những đơn vị cung cấp dịch vụ của Nhật khác hứa hẹn mang đến những trải nghiệm đậm chất Nhật Bản tại Taisei Square Hanoi. ",
    "BUILDING DESIGN CONCEPT": "Ý TƯỞNG THIẾT KẾ CHỦ ĐẠO",
    "BUILDING DESIGN CONCEPT DESCRIPTION": "Lấy cảm hứng từ sự tỉ mỉ và nguyên tắc của kiến trúc Nhật Bản, đặc trưng bởi các đường nét vuông vắn hoàn hảo tạo nên từ tay nghề của các nghệ nhân, thiết kế không gian của Tòa nhà toát lên sự trang trọng và tinh tế, mà vẫn ẩn chứa sự ấm áp, thanh bình nhờ việc kết hợp các vật liệu tự nhiên như gỗ và đá.",
    "LOGO CONCEPT": "KHÁI NIỆM TÊN & LOGO",
    "LOGO CONCEPT DESCRIPTION": "<p>&quot;SQUARE&quot; - &quot;hình vuông&quot;, biểu trưng cho hình khối giản đơn mà mạnh mẽ của Tòa nhà, đồng thời mang ý nghĩa khác là &quot;quảng trường&quot;, là nơi giao thương nhộn nhịp của khu vực Tây Hà Nội.</p> <p style='padding-top: 10px;'> Chấm đỏ trong logo chính là mặt trời trên quốc kỳ Nhật Bản, còn chữ T lấy gợi ý từ T-square, thước chữ T, một công cụ vẽ truyền thống không thể thiếu của các kiến trúc sư. Cái tên và biểu tượng của Tòa nhà thể hiện khát vọng tạo ra một môi trường làm việc hiện đại chất lượng Nhật Bản từ tinh thần và giá trị kiến tạo truyền thống.</p>",
    "LOCATION": "VỊ TRÍ",
    "LOCATION DESCRIPTION": "Có mặt tiền đối diện đường Vành đai 3, Taisei Square Hanoi tọa lạc tại vị trí chiến lược kết nối thuận tiện tới các trung tâm tài chính, thương mại khác của Hà Nội, cũng như tới sân bay quốc tế Nội Bài và các khu công nghiệp tiệm cận.",
    "30 MINS": "30 PHÚT",
    "NOI BAI INTERNATIONAL AIRPORT": "Tới <br /> sân bay <br /> quốc tế Nội Bài",
    "HOAN KIEM DISTRICT": "Tới <br /> quận <br /> Hoàn Kiếm",
    "15 MINS": "15 PHÚT",
    "DONG DA AND BA DINH DISTRICT": "Tới <br /> quận Đống Đa và <br /> Ba Đình",
    "3 MINS": "3 PHÚT",
    "LANG HOA LAC HIGHWAY": "Vào <br /> cao tốc <br /> Láng - Hòa Lạc",
    "CONTACT US": "LIÊN HỆ",
    "EXPLORE": "EXPLORE"
}
