import styled from "styled-components";
import { Carousel } from "antd";

export const DeveloperCarousel = styled(Carousel)`
    width: 90vw;
    .slick-slide {
        width: 90vw;
    }
    .slick-dots li.slick-active button {
        background: #154dac;
        width: 50px;
    }
    .slick-dots li button {
        background: #444444;
        width: 50px;
    }
    .slick-dots li {
        width: 50px;
    }
    .slick-dots li.slick-active {
        width: 50px;
    }
    .slick-dots {
        margin: 25px 0 0 0 !important;
        position: relative !important;
    }
`;

export const HeroLanding = styled.div`
    background-image: url(./img/${(props) => props.bgurl});
    background-position: 50% 50%;
    background-size: cover;
    width: 100%;
    z-index: 9999;
    display: flex;
    align-items: flex-end;
    height: 100vh;
`;

export const DeveloperSection = styled.div`
    display: flex;
    justify-content: ${(props) => props.justify ?? "center"};
    align-items: ${(props) => props.alignitems ?? ""};
    position: relative;
    width: 100%;
    height: ${(props) => props.height};
`;

export const DeveloperDisplay = styled.div`
    display: flex;
    flex-direction: ${(props) => props.direction ?? ""};
    justify-content: center;
    align-items: center;
    margin: ${(props) => props.margin ?? ""};
    gap: ${(props) => props.gap ?? ""};

    @media (min-width: 768px) and (max-width: 1279px) {
        margin: ${(props) => props.mgtablet ?? ""};
    }
`;

export const DeveloperGrid = styled.div`
    display: grid;
    grid-template-columns: 100%;
    gap: ${(props) => props.gap ?? "10px"};
    justify-content: center;
    align-items: center;
    margin: ${(props) => props.margin ?? ""};

    @media (min-width: 768px) and (max-width: 1279px) {
        grid-template-columns: 50% 50%;
        gap: 0;
        row-gap: 30px;
    }
`;

export const DeveloperImageFit = styled.img`
    height: ${(props) => props.height};
    left: ${(props) => props.left ?? "0"};
    object-fit: ${(props) => props.fit ?? "cover"};
    position: ${(props) => props.position ?? "absolute"};
    top: ${(props) => props.top ?? "0"};
    width: ${(props) => props.width};
    cursor: ${(props) => props.cursor};
`;

export const DeveloperImage = styled.img`
    width: ${(props) => props.width ?? "100%"};
    height: ${(props) => props.height ?? ""};
    margin: ${(props) => props.margin ?? ""};
    object-fit: ${(props) => props.fit ?? "cover"};
    transform: ${(props) => props.transform ?? "scale(1)"};
    transition: transform 0.3s;
    cursor: ${(props) => props.cursor};

    @media (min-width: 768px) and (max-width: 1279px) {
        width: ${(props) => props.wtablet};
        height: ${(props) => props.htablet};
    }
`;

export const ContentExplore = styled.div`
    background-color: ${(props) => props.bgcolor};
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    padding: ${(props) => props.padding ?? ""};
    display: flex;
    flex-direction: column;
`;

export const DeveloperParagraphs = styled.div`
    text-align: justify;
    text-align-last: left;
    color: ${(props) => props.color};
    font-family: ${(props) => (props.languagecode === "jp" ? "'HiraKaku', sans-serif" : props.font ?? "'Barlow', Helvetica")};
    font-weight: ${(props) => props.fweight};
    font-size: ${(props) => props.fsize};
    line-height: ${(props) => props.linehg};
    text-shadow: 0 2px 4px #353535;
    letter-spacing: 0px;
    @media (min-width: 786px) and (max-width: 1279px) {
        font-size: ${(props) => props.tablet};
        line-height: ${(props) => props.tabletlinehg};
    }
`;

export const DivWrapper = styled.div`
    font-family: ${(props) => props.font ?? "'Barlow', Helvetica"};
    font-weight: ${(props) => props.fweight};
    font-size: ${(props) => props.fsize};
    line-height: ${(props) => props.linehg};
    color: ${(props) => props.color};
    text-shadow: 0 2px 4px #353535;
    width: 1000px;
`;

export const SpanWrapper = styled.span`
    font-weight: ${(props) => props.fweight ?? "700"};
    font-family: inherit;
`;

export const HeroContentExplore = styled.div`
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${(props) => props.padding};
    gap: 8px;
    width: 100%;
`;

export const DeveloperContent = styled.div`
    width: 100%;
    display: flex;
    padding: ${(props) => props.font};
    margin: ${(props) => props.margin};
    align-self: ${(props) => props.alignself};
    justify-content: ${(props) => props.content};
`;

export const DeveloperWrapper = styled.div`
    background-color: ${(props) => props.bgcolor};
    border: ${(props) => props.border};
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    display: flex;
    padding: ${(props) => props.padding};
    margin: ${(props) => props.margin};
    justify-content: ${(props) => props.justify};
    flex-direction: ${(props) => props.direction};
    gap: ${(props) => props.gap};
    align-items: ${(props) => props.align};

    @media (min-width: 768px) and (max-width: 1279px) {
        font-size: ${(props) => props.tablet ?? "21px"};
        line-height: ${(props) => props.tablet ?? "31.5px"};
        margin: ${(props) => props.mgtablet};
    }
`;

export const ImageContainer = styled.div`
    transition: transform 0.3s;
    cursor: pointer;
    overflow: hidden;
    &:hover img {
        transform: scale(1.1);
    }
    &:hover div {
        transform: scale(1.1);
    }
`;

export const HeadLineWrapper = styled.span`
    color: ${(props) => props.color ?? "#353535"};
    font-family: ${(props) => (props.languagecode === "jp" ? "'HiraKaku', sans-serif" : props.font ?? "'Barlow', Helvetica")};
    font-weight: ${(props) => props.fweight};
    font-size: ${(props) => props.fsize};
    line-height: ${(props) => props.linehg};
    white-space: ${(props) => props.wspace};
    letter-spacing: 0;
    text-align: ${(props) => props.align};
    margin: ${(props) => props.margin};
    padding: ${(props) => props.padding};
    width: ${(props) => props.width};

    @media (min-width: 768px) and (max-width: 1279px) {
        font-size: ${(props) => props.tablet ?? "21px"};
        line-height: ${(props) => props.tablet ?? "25px"};
    }
    @media (max-width: 360px) {
        font-size: 15px;
    }
`;

export const Retangle = styled.div`
    background-color: #cbe7f2;
    width: 50px;
    height: 10px;
    margin-bottom: ${(props) => props.mgbottom ?? "2px"};
    @media (max-width: 360px) {
        font-size: 16px;
        margin-top: 5px;
    }
`;

export const ParagraphWrapper = styled.span`
    color: ${(props) => props.color ?? "#353535"};
    font-family: ${(props) => (props.languagecode === "jp" ? "'HiraKaku', sans-serif" : props.font ?? "'Segoe UI', Helvetica")};
    font-size: ${(props) => props.fsize ?? "15px"};
    font-weight: ${(props) => props.fweight ?? "400"};
    height: ${(props) => props.height};
    letter-spacing: 0;
    line-height: ${(props) => props.linehg ?? "24px"};
    width: ${(props) => props.width};
    text-align: ${(props) => props.align};
    margin: ${(props) => props.margin};

    @media (min-width: 768px) and (max-width: 1279px) {
        font-size: ${(props) => props.fsizetablet ?? "18px"};
        line-height: ${(props) => props.linehgtablet ?? "27px"};
    }
`;

export const DeveloperSpan = styled.span`
    color: ${(props) => props.color ?? "#353535"};
    font-family: ${(props) => (props.languagecode === "jp" ? "'HiraKaku', sans-serif" : props.font ?? "'Segoe UI', Helvetica")};
    font-weight: ${(props) => props.fweight ?? "700"};
    font-size: ${(props) => props.fsize ?? "15px"};
    line-height: ${(props) => props.linehg ?? "24px"};
    letter-spacing: 0;
    text-align: center;
    margin: ${(props) => props.margin};
    width: ${(props) => props.width};
    height: ${(props) => props.height};
`;

export const VerticalLine = styled.div`
    width: 2px;
    height: 78px;
    background-color: gray;
    box-shadow: 0.5px 0.5px 5px 0px gray;
    margin-top: 20px;
`;
