import { HeroLanding, TheBuildingDisplay, TheBuildingDivContent, TheBuildingJustifyContent, TheBuildingTitle, TheBuildingButtonContact } from "../../../style/TheBuildingScreen";
import HeroButtonComponent from "../../../components/ContactUsButton";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";

const HeroMobile = () => {
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            const yOffset = section.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({ top: yOffset, behavior: "smooth" });
        } else {
            window.scrollTo({ top: 0, behavior: "smooth" });
            setTimeout(() => {
                const sectionFt = document.getElementById(sectionId);
                if (sectionFt) {
                    const yOffset = sectionFt.getBoundingClientRect().top;
                    window.scrollTo({ top: yOffset, behavior: "smooth" });
                }
            }, 500);
        }
    };

    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);

    return (
        <HeroLanding id="hero" bgurl="2-hero.png">
            <TheBuildingDisplay>
                <TheBuildingDivContent padding={"0px 20px 0px 20px"}>
                    <TheBuildingTitle languagecode={currentLanguageCode} color={"#F4F4F4"} fsize={"47px"} linehg={"57px"} fweight={"600"} font={"Barlow, Helvetica"} shadow={"3px 3px 6px #353535"}>
                        {parse(t("EMBRACE THE POWER OF THE SQUARE MOBILE"))}
                    </TheBuildingTitle>
                </TheBuildingDivContent>
                <TheBuildingJustifyContent>
                    <TheBuildingButtonContact>
                        <Link to="/leasing#office" onClick={() => scrollToSection("office")}>
                            <HeroButtonComponent width={"150px"} text={"EXPLORE"} color={"#F4F4F4"} />
                        </Link>
                    </TheBuildingButtonContact>
                </TheBuildingJustifyContent>
            </TheBuildingDisplay>
        </HeroLanding>
    );
};

export default HeroMobile;
