import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import MoreDetailButtonComponent from "../../../components/ContactUsButton";
import { TextAnimation } from "../../../breakpoints";
import { animated } from "@react-spring/web";
import { useInView } from "react-intersection-observer";
import { LeasingSectionBackground, LeasingParagraphs, ElementLeasing, LeasingWrapper, LeasingImage, LeasingContent } from "../../../style/LeasingScreen";
import { useTranslation } from "react-i18next";

const RetailMobile = () => {
    const [retailRef, inRetailRef] = useInView();
    const descriptionSpring = TextAnimation(inRetailRef);
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);

    return (
        <LeasingSectionBackground id="retail" ref={retailRef}>
            <LeasingWrapper className="retail-space" height={"auto"} position={"relative"} width={"100vw"}>
                <LeasingParagraphs languagecode={currentLanguageCode} font={"'Barlow', Helvetica"} fweight={"700"} fsize={"32px"} linehg={"55px"} padding={"30px 0px"}>
                    <animated.div style={descriptionSpring}>{t("RETAIL SPACE")}</animated.div>
                </LeasingParagraphs>
                <ElementLeasing className="floorplan-1-retail" height={"auto"} position={""} width={"100%"} border={"1px solid #707070"}>
                    <LeasingImage className="img-floorplan1" src="./img/floorplan-1-retail.png" height={"auto"} width={"100%"} />
                </ElementLeasing>
                <ElementLeasing className="floorplan-2-retail" height={"auto"} position={""} width={"100%"} border={"1px solid #707070"} margin={"30px 0px"}>
                    <LeasingImage className="img-floorplan2" src="./img/floorplan-2-retail.png" height={"auto"} width={"100%"} />
                </ElementLeasing>
                <LeasingContent className="moredetail" height="43px" justify={"center"} width={"100vw"} margin={"0 0 30px 0"}>
                    <Link to="/contact">
                        <MoreDetailButtonComponent text={"MORE DETAILS"} width={"210px"} />
                    </Link>
                </LeasingContent>
            </LeasingWrapper>
        </LeasingSectionBackground>
    );
};

export default RetailMobile;
