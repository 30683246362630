import React, { useEffect, useState } from "react";
import { TheBuildingSectionWrapper, TheBuildingDisplayAlignItems, TheBuildingDivContent, TheBuildingParagraphs, HoverImageMobile, AmenitiesIcon, TheBuildingJustifyContent, TheBuildingButtonContact, SelectedIcon, FadeInOut, TheBuildingImage, TheBuildingGrid } from "../../../style/TheBuildingScreen";
import { amenities } from "../Data/amenities";
import parse from "html-react-parser";
import PropTypes from "prop-types";
import ContactUsButtonComponent from "../../../components/ContactUsButton";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";

const Amenity = ({ kf }) => {
    const { key, title, description, image } = kf;
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    return (
        <FadeInOut key={key}>
            <TheBuildingDivContent padding={"40px 20px 20px 20px"}>
                <TheBuildingParagraphs tablet={"25px"} color={"#2C53AF"} fsize={"17px"} linehg={1.15} fweight={"600"} languagecode={currentLanguageCode} font={"'Barlow', Helvetica"}>
                    {parse(t(title))}
                </TheBuildingParagraphs>
            </TheBuildingDivContent>
            <TheBuildingDivContent padding={"0px 20px 30px 20px"}>
                <TheBuildingParagraphs tablet={"18px"} color={"#353535"} align={"justify"} fsize={"13px"} linehg={"21px"} fweight={"400"} languagecode={currentLanguageCode}>
                    {parse(t(description))}
                </TheBuildingParagraphs>
            </TheBuildingDivContent>
            <TheBuildingDivContent padding={"0px 0px 30px 0px"}>
                <TheBuildingImage border={"solid 1px #535353"} alt={image} src={image} />
            </TheBuildingDivContent>
        </FadeInOut>
    );
};

const AmenitiesIconComponent = ({ kf, className, tabKey, changeTab, gridColumn }) => {
    const { icon } = kf;
    const iconImage = tabKey === kf.key ? icon.activated : icon.normal;
    const colorActive = tabKey === kf.key ? "#16f0df" : "#2c53af";
    const sizeIcon = kf.key === "tap" ? "40px" : kf.key === "car" || kf.key === "parking" ? "57px" : "47px";
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    return (
        <HoverImageMobile gridcolumn={gridColumn}>
            <TheBuildingDivContent onClick={() => changeTab(kf.key)} onKeyDown={() => changeTab(kf.key)}>
                <AmenitiesIcon className={`amenities-icon ${className ?? ""}`} alt={iconImage} src={iconImage} height={"60px"} width={sizeIcon} position={""} />
                <TheBuildingParagraphs tablet={"18px"} color={colorActive} fweight={"600"} languagecode={currentLanguageCode}>
                    {parse(t(icon.title))}
                </TheBuildingParagraphs>
            </TheBuildingDivContent>
        </HoverImageMobile>
    );
};

Amenity.propTypes = {
    kf: PropTypes.object
};

AmenitiesIconComponent.propTypes = {
    kf: PropTypes.object,
    className: PropTypes.string,
    tabKey: PropTypes.string,
    changeTab: PropTypes.func,
    gridColumn: PropTypes.string
};

const AmenitiesMobile = () => {
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;
    const [tabKey, setTabKey] = useState("parking");
    const [currentKey, setCurrentKey] = useState(null);

    const changeTab = (index) => {
        ReactGA.event({
            category: "Click",
            action: "Click",
            label: `Amenities selected icon ${index}`
        });
        setTabKey(index);
    };

    useEffect(() => {
        const kf = amenities.find(({ key }) => key === tabKey);
        if (kf) {
            setCurrentKey(kf);
        }
    }, [tabKey]);

    const AmenitiesSelectionPanel = () => {
        let kfs = amenities.map((item, index) => {
            return <AmenitiesIconComponent key={index} kf={item} changeTab={changeTab} gridColumn={index === 6 ? "span 3" : ""} tabKey={tabKey} />;
        });

        return <TheBuildingGrid padding={""}>{kfs}</TheBuildingGrid>;
    };

    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);

    return (
        <TheBuildingSectionWrapper id="amenities" bgcolor={"#f4f4f4"}>
            <TheBuildingDisplayAlignItems>
                <TheBuildingDivContent padding={"30px 0px"}>
                    <TheBuildingParagraphs fsize={"27px"} linehg={"27px"} fweight={"700"} languagecode={currentLanguageCode} font={"'Barlow', Helvetica"}>
                        {t("AMENITIES")}
                    </TheBuildingParagraphs>
                </TheBuildingDivContent>
                <TheBuildingDivContent padding={"0px 23px"}>
                    <TheBuildingParagraphs tablet={"18px"} fsize={"13px"} align={"justify"} linehg={"21px"} fweight={"400"} languagecode={currentLanguageCode}>
                        {t("AMENITIES DESCRIPTION")}
                    </TheBuildingParagraphs>
                </TheBuildingDivContent>
                <TheBuildingJustifyContent>
                    <TheBuildingButtonContact height={"2px"}>
                        <Link to="/contact">
                            <ContactUsButtonComponent text={t("CONTACT US")} width={"150px"} textml={"12px"} hidden={true} />
                        </Link>
                    </TheBuildingButtonContact>
                </TheBuildingJustifyContent>
                <AmenitiesSelectionPanel />
                <SelectedIcon>{currentKey && <Amenity kf={currentKey} />}</SelectedIcon>
            </TheBuildingDisplayAlignItems>
        </TheBuildingSectionWrapper>
    );
};

export default AmenitiesMobile;
