{
    "Overview": "Tổng quan",
    "SubOverview": "Dự án",
    "Concept": "Ý tưởng",
    "Location": "Vị trí",
    "The Building": "Tòa nhà",
    "Key Features": "Đặc điểm nổi bật",
    "Amenities": "Tiện ích",
    "Sustainability": "Phát triển bền vững",
    "Leasing": "Cho thuê",
    "Office": "Văn phòng",
    "Retail": "Thương mại",
    "Virtual Tour": "Tham quan",
    "360Tour": "360° Tour",
    "Photo Gallery": "Thư viện ảnh",
    "Developer": "Nhà phát triển",
    "About Us": "Về chúng tôi",
    "SubDeveloper": "Nhà phát triển",
    "Investor": "Nhà đầu tư",
    "Partners": "Đối tác",
    "Media": "Truyền thông",
    "News": "Tin tức",
    "Leasing Events": "Sự kiện",
    "Press": "Báo chí",
    "Contact": "Liên hệ",
    "Disclaimer": "Miễn trừ trách nhiệm",
    "ContentFooter": "Mặc dù chúng tôi đã hết sức cẩn trọng để đảm bảo tính chính xác trong việc chuẩn bị và trình bày những thông tin và thông số kỹ thuật trên đây, tuy nhiên chúng tôi không đưa ra bất kỳ bảo đảm nào cũng như công nhận tính pháp lý của những thông tin đó. Các Bên quan tâm cần dựa vào tài liệu giao dịch chính thức. Những hình thức trình bày trực quan bao gồm các bản vẽ, hình vẽ, ảnh chụp và phối cảnh diễn họa chỉ nhằm mục đích mô tả minh họa và không được coi là đại diện cho thực tế hoặc pháp luật. Nhà phát triển có quyền sửa đổi công năng, đơn vị, thiết kế, kế hoạch hoặc bất kỳ phần nào trong đó."
}
