import React, { useState } from "react";
import "../../style/style.css";
import DesktopLayout from "../../components/DesktopLayout";
import * as Sections from "./Sections";
import * as MobileSections from "./Mobile.Sections";
import { useWindowWidth } from "../../breakpoints";

const OverviewScreen = () => {
    const screenWidth = useWindowWidth();
    const [totalHeight, setTotalHeight] = useState(0);

    const handleHeightChange = (height) => {
        setTotalHeight(height);
    };
    return (
        <>
            {screenWidth >= 1280 ? (
                <DesktopLayout screenWidth={screenWidth} screenHeight={`${totalHeight - 35}px`}>
                    <Sections.HeroSection onHeightChange={handleHeightChange} />
                    <Sections.OverviewSection onHeightChange={handleHeightChange} />
                    <Sections.ConceptSection onHeightChange={handleHeightChange} />
                    <Sections.LocationSection onHeightChange={handleHeightChange} />
                </DesktopLayout>
            ) : (
                <>
                    <DesktopLayout screenWidth={screenWidth} screenHeight={"auto"}>
                        <MobileSections.HeroMobile />
                        <MobileSections.OverviewMobile />
                        <MobileSections.ConceptMobile />
                        <MobileSections.LocationMobile />
                    </DesktopLayout>
                </>
            )}
        </>
    );
};

export default OverviewScreen;
