{
    "NEWS & PRESS": "ニュース & プレス",
    "NEWS": "ニュース",
    "PAST NEWS": "過去",
    "READ MORE": "詳細",
    "PREV": "PREV",
    "NEXT": "NEXT",
    "UPCOMING LEASING EVENT": "今後のイベント",
    "PAST EVENTS": "過去のイベント",
    "TITLE_ARTICLE": "プレス",
    "PAST PRESS": "過去ニュース",
    "PRESS": "プレス",
    "PAST": "過去"
}
