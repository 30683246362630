import { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import { policies } from "../Data/policies";
import { PolicyDisplay, PolicyContent, PolicyParagraph } from "../../../style/PrivacyPolicy";

const PrivacyPolicy = ({ onHeightChange, languageCode }) => {
    const sectionRef = useRef(null);
    const [content, setContent] = useState("");

    const getContentById = (code) => {
        const policy = policies.find((item) => item.id === code);
        setContent(policy ? policy.content : "");
    };

    useEffect(() => {
        const sectionHeight = sectionRef.current ? sectionRef.current.clientHeight : 0;
        onHeightChange(sectionHeight);
        getContentById(languageCode);
    }, [onHeightChange, languageCode]);

    return (
        <PolicyDisplay ref={sectionRef}>
            <PolicyContent width={"1366px"} padding={"10px "}>
                <PolicyParagraph align={"left"} fweight={"400"}>
                    {parse(content)}
                </PolicyParagraph>
            </PolicyContent>
        </PolicyDisplay>
    );
};

PrivacyPolicy.propTypes = {
    onHeightChange: PropTypes.func,
    languageCode: PropTypes.string
};

export default PrivacyPolicy;
