{
    "NEWS & PRESS": "TIN TỨC & BÁO CHÍ",
    "NEWS": "TIN TỨC",
    "PAST NEWS": "TIN ĐÃ ĐĂNG",
    "READ MORE": "CHI TIẾT",
    "PREV": "TRƯỚC",
    "NEXT": "SAU",
    "UPCOMING LEASING EVENT": "SỰ KIỆN CHO THUÊ MỚI NHẤT",
    "PAST EVENTS": "SỰ KIỆN ĐÃ DIỄN RA",
    "TITLE_ARTICLE": "BÁO CHÍ",
    "PAST": "TIN TỨC CŨ"
}
