import React, { useEffect } from "react";
import { DeveloperDisplay, HeroLanding, DeveloperImage, ContentExplore, DeveloperParagraphs, SpanWrapper, DeveloperWrapper } from "../../../style/DeveloperScreen";
import ExploreButtonComponent from "../../../components/ContactUsButton";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

const HeroMobile = () => {
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    const LinkClickedHandler = () => {
        window.open("https://www.taisei.co.jp/english/", "_blank", "noopener");
    };

    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);

    return (
        <HeroLanding id="hero" position={"relative"} bgurl={"5-hero.png"}>
            <DeveloperDisplay direction={"column"} margin={"0 22px"} mgtablet={"0"}>
                <DeveloperImage src="./img/taiseicorp-logo.png" margin={"0 0 5px 0"} wtablet={"40%"} htablet={"40%"} cursor={"pointer"} onClick={LinkClickedHandler} />
                <ContentExplore bgcolor={"#122658d9"} padding={"24px"}>
                    <DeveloperParagraphs tabletlinehg={"24px"} tablet={"16px"} fsize={"13px"} linehg={"21px"} align={"left"} fweight={"400"} color={"#f4f4f4"} languagecode={currentLanguageCode} font={"'Segoe UI', Helvetica"}>
                        <SpanWrapper fweight={"400"}>{parse(t("Taisei Content"))}</SpanWrapper>
                    </DeveloperParagraphs>
                    <DeveloperWrapper justify={"center"}>
                        <DeveloperWrapper mgtablet={"10px 0 0 0"} margin={"33px 0 0 0"} width={"150px"} height={"40px"} onClick={LinkClickedHandler}>
                            <ExploreButtonComponent text={t("EXPLORE")} width={"150px"} color={"#F4F4F4"} />
                        </DeveloperWrapper>
                    </DeveloperWrapper>
                </ContentExplore>
            </DeveloperDisplay>
        </HeroLanding>
    );
};

export default HeroMobile;
