{
    "Taisei Corporation": "Taisei Corporation",
    "Taisei Content": "Taisei Corporation is one of the top 5 major construction general contractors in Japan, with 150 years of history. Over its growing process, Taisei has developed and built many large-scaled projects in Japan as well as overseas.<p style='padding-top: 10px'>In 2020, Taisei announced its very first investment in Vietnam, Taisei Square Hanoi office tower, which was developed, designed, and built 100% by its subsidiaries.</p>",
    "NOTABLE ACHIEVEMENTS IN VIETNAM": "NOTABLE ACHIEVEMENTS IN VIETNAM",
    "Developed and Constructed by Taisei": " Developed and Constructed by Taisei",
    "ROYGENT PARKS HANOI": "ROYGENT PARKS HANOI",
    "ROYGENT PARKS HANOI CONTENT": "This 24-storey hotel and service apartment block which was developed with a Japanese partner has introduced and been providing Japanese quality and hospitality.",
    "T2 NOI BAI INTL AIRPORT": "T2 NOI BAI INTERNATIONAL AIRPORT",
    "T2 NOI BAI INTL AIRPORT CONTENT": "The new terminal that can handle 15 million passengers annually is dedicated for all international flights to and from Hanoi. Taisei took part as one of the main contractors for this ODA project.",
    "Constructed by Taisei": "Constructed by Taisei",
    "CAN THO BRIDGE": "CAN THO BRIDGE",
    "CAN THO BRIDG CONTENT": "The regional largest cable-stayed bridge that looks like a soft silk strip spanning over Hau river was built by Taisei Corporation and 2 more Japanese contractors and serves as a symbol of Can Tho.",
    "NOTABLE ACHIEVEMENTS IN JAPAN": "NOTABLE ACHIEVEMENTS IN JAPAN",
    "THE OKURA TOKYO": "THE OKURA TOKYO",
    "THE OKURA TOKYO CONTENT": "Rebuilt on the site where the first Hotel Okura opened in 1962, The Okura Tokyo remains the epitome of Japanese hospitality and luxury. Taisei took main role in the redevelopment of this historic site.",
    "OCHANOMIZU SOLACITY": "OCHANOMIZU SOLA CITY",
    "OCHANOMIZU SOLACITY CONTENT": "Designed and built by Taisei, Sola City is a 23-floor commercial complex building that exudes an intelligent mood, a true barrier free and sustainable setting in the academic culture rich vicinity of Ochanomizu.",
    "NATIONAL STADIUM": "JAPAN NATIONAL STADIUM",
    "NATIONAL STADIUM CONTENT": "The new National Stadium of Japan was designed together with the famous architect Kuma Kengo and built by Taisei. Timber is used as part of the amajor component of the structure. Wood elements used for part of the roof and canopy are sourced from all 47 prefectures of Japan.",
    "TAISEI DEVELOPMENT HANOI": "TAISEI DEVELOPMENT HANOI",
    "TAISEI DEVELOPMENT HANOI CONTENT": "The developer and operator of Taisei Square Hanoi, a group company of Taisei Corporation. A small but dedicative team of Japanese and Vietnamese staffs has been doing their best to bring true Japanese quality and professionalism to valued customers in Vietnam.",
    "OUR PARTNERS": "OUR PARTNERS",
    "Main Contractor & Architect": "Main Contractor & Architect",
    "Design Consultant": "Design Consultant",
    "M&E Contractor KINDEN": "M&E Contractor",
    "M&E Contractor YURTEC": " M&E Contractor",
    "Project Management & Site Supervision": "Project Management <br /> & Site Supervision",
    "Strategic Leasing & Marketing Consultant": "Strategic Leasing & Marketing Consultant",
    "Property Management Support": "Property Management Support",
    "Security Service": "Security Service",
    "Cleaning Service": "Cleaning Service",
    "Developed and": "Developed and",
    "CONTACT US": "CONTACT US",
    "EXPLORE": "EXPLORE"
}
