import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { ContentExplore, HeroLanding, DivWrapper, DeveloperImage, HeroContentExplore, DeveloperWrapper, SpanWrapper } from "../../../style/DeveloperScreen";
import ExploreButtonComponent from "../../../components/ContactUsButton";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

const HeroSection = ({ onHeightChange }) => {
    const sectionRef = useRef(null);
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    const LinkClickedHandler = () => {
        window.open("https://www.taisei.co.jp/english/", "_blank", "noopener");
    };

    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);

    useEffect(() => {
        const sectionHeight = sectionRef.current ? sectionRef.current.clientHeight : 0;
        onHeightChange(sectionHeight);
    }, [onHeightChange]);

    return (
        <HeroLanding id="hero" position={"relative"} bgurl={"5-hero.png"} ref={sectionRef}>
            <HeroContentExplore>
                <ContentExplore bgcolor={"#122658d9"} padding={"24px"}>
                    <DivWrapper fsize={"15px"} linehg={"24px"} fweight={"400"} color={"#f4f4f4"} languagecode={currentLanguageCode} font={"'Barlow', Helvetica"}>
                        <SpanWrapper fweight={"400"}>{parse(t("Taisei Content"))}</SpanWrapper>
                    </DivWrapper>
                    <DeveloperWrapper justify={"space-between"}>
                        <DeveloperWrapper margin={"33px 0 0 0"} width={"150px"} height={"40px"} onClick={LinkClickedHandler}>
                            <ExploreButtonComponent text={t("EXPLORE")} width={"150px"} color={"#F4F4F4"} />
                        </DeveloperWrapper>

                        <DeveloperWrapper width={"339px"} height={"90px"} justify={"end"} align={"flex-end"} onClick={LinkClickedHandler}>
                            <DeveloperImage src="./img/taiseicorp-logo.png" height={"80%"} fit={"cover"} width={"339px"} cursor={"pointer"} />
                        </DeveloperWrapper>
                    </DeveloperWrapper>
                </ContentExplore>
            </HeroContentExplore>
        </HeroLanding>
    );
};

HeroSection.propTypes = {
    onHeightChange: PropTypes.func
};

export default HeroSection;
