import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ContactUsButtonComponent from "../../../components/ContactUsButton";
import { HeroLanding, MediaDisplay, MediaHeroWrapper } from "../../../style/MediaScreen";
import { useTranslation } from "react-i18next";

const HeroMobile = () => {
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);

    return (
        <HeroLanding id="hero" position="relative" bgurl="6-hero.png">
            <MediaDisplay direction={"column"} align={"center"}>
                <MediaHeroWrapper languagecode={currentLanguageCode} color={"#F4F4F4"} fsize={currentLanguageCode === "jp" ? "42px" : "44px"} linehg={"70px"} shadow={"0px 2px 4px #353535"} wspace={""} fweight={"600"}>
                    {t("NEWS & PRESS")}
                </MediaHeroWrapper>
                <div style={{ paddingTop: "20px" }}>
                    <Link to="/contact">
                        <ContactUsButtonComponent color={"#F4F4F4"} width={"200px"} text={"MORE DETAILS"} hidden={true} />
                    </Link>
                </div>
            </MediaDisplay>
        </HeroLanding>
    );
};

export default HeroMobile;
